import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";
import { clearWishList } from "./wishListSlice";

const initialState = {
  token: null,
  isProfileCompleted: false,
  userInfo: null,

  chooseProperty: "RESIDENTIAL",
  // signup variables
  selectedSignupStep: {
    step: 1,
    type: "STANDARD",
  },
  liveLatLongAddress: null,

  // navbar selected tab (1st header tab , 2nd header tab)
  navbarSelectedTab: {
    exploreORmyproject: "explore",
    tabSelectedFrom2ndNav: "construction",
  },

  // sidebar myaccount variables
  myAccountSelectedTab: "myaccount",

  // sidebar selected tab
  sidebarFilterSelectedTab: {
    ongoingFilterTab: "ALL",
    materialFilterTab: {},
  },

  showProjectDetails: false,

  // show & hide chat on mobile and selected chat
  showChatDetails: {
    selectedUserDetails: {},
    showChatOnMobile: false,
  },
};

export const SendOtp = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.sendOtp(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const VerifyOtpAndLogin = createAsyncThunk(
  "verifyOtpAndLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.verifyOtpAndLogin(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const RegisterDreamHomeRequirements = createAsyncThunk(
  "registerDreamHomeRequirements",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.registerDreamHomeRequirements(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const UpdateProfile = createAsyncThunk(
  "updateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.updateProfile(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const Contactus = createAsyncThunk(
  "contactus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.sendOtp(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setProfileCompleted(state, action) {
      state.isProfileCompleted = action.payload;
    },
    // signup variables
    setSelectedSignupStep: (state, action) => {
      state.selectedSignupStep = action.payload;
    },
    setLiveLatLongAddress: (state, action) => {
      state.liveLatLongAddress = action.payload;
    },
    // navbar selected tab
    setNavbarSelectedTab: (state, action) => {
      state.navbarSelectedTab = action.payload;
    },
    // sidebar myaccount variables
    setMyAccountSelectedTab: (state, action) => {
      state.myAccountSelectedTab = action.payload;
    },
    // sidebar filter selected tab
    setSidebarFilterSelectedTab: (state, action) => {
      state.sidebarFilterSelectedTab = action.payload;
    },
    // discussion tab
    setChatDetailsSelectedUser: (state, action) => {
      state.showChatDetails = action.payload;
    },
    setShowProjectDetails: (state, action) => {
      state.showProjectDetails = action.payload;
    },
    setChoosePropertyType: (state, action) => {
      state.chooseProperty = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.userInfo = null;
      state.isProfileCompleted = false;
      state.navbarSelectedTab = {
        exploreORmyproject: "explore",
        tabSelectedFrom2ndNav: "construction",
      };
      state.myAccountSelectedTab = "";
      clearWishList();
    },
  },
  extraReducers: (builder) => {
    //user send otp case
    builder.addCase(SendOtp.pending, (state, action) => {});
    builder.addCase(SendOtp.fulfilled, (state, action) => {
      console.log("resp of SendOtp from slice", action.payload);
    });
    builder.addCase(SendOtp.rejected, (state, action) => {});

    //user verify otp and login case
    builder.addCase(VerifyOtpAndLogin.pending, (state, action) => {});
    builder.addCase(VerifyOtpAndLogin.fulfilled, (state, action) => {
      // console.log("resp of VerifyOtpAndLogin from slice", action.payload.data);
      state.token = action?.payload?.data?.token;
      state.userInfo = action?.payload?.data?.user;
    });
    builder.addCase(VerifyOtpAndLogin.rejected, (state, action) => {});

    // update profile  case
    builder.addCase(UpdateProfile.pending, (state, action) => {});
    builder.addCase(UpdateProfile.rejected, (state, action) => {});
    builder.addCase(UpdateProfile.fulfilled, (state, action) => {
      console.log("response of update profile", action.payload);
    });

    //user verify otp and login case
    builder.addCase(
      RegisterDreamHomeRequirements.pending,
      (state, action) => {}
    );
    builder.addCase(
      RegisterDreamHomeRequirements.fulfilled,
      (state, action) => {
        console.log(
          "resp of RegisterDreamHomeRequirements from slice",
          action.payload
        );
        //  state.token = action.payload;
      }
    );
    builder.addCase(
      RegisterDreamHomeRequirements.rejected,
      (state, action) => {}
    );
  },
});

export const {
  logout,
  setToken,
  setSelectedSignupStep,
  setLiveLatLongAddress,
  setNavbarSelectedTab,
  setMyAccountSelectedTab,
  setSidebarFilterSelectedTab,
  setChatDetailsSelectedUser,
  setShowProjectDetails,
  setChoosePropertyType,
  setProfileCompleted,
  setProjectCreated,
  setUserInfo,
} = userSlice.actions;
export default userSlice.reducer;
