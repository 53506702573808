import React, { useState } from "react";
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import "./Myproject.css";
import FilterImage from "../../assets/sidebar/filter.svg";

// components
import Ongoing from "./Ongoing/Ongoing";
import Payments from "./Payments/Payments";
import Discussions from "./Discussions/Discussions";

// images
import CreateButton from "../../assets/Construction/createButton.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setNavbarSelectedTab,
  setShowProjectDetails,
} from "../../Redux/Slice/userSlice";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import OngoingProjectsFilterMobile from "../../components/Sidebar/OngoingProjectsFilterComp/OngoingProjectsFilterMobile";
import { useLocation } from "react-router-dom";

export default function Myproject() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { navbarSelectedTab, showProjectDetails } = useSelector(
    (state) => state.user
  );

  const TabSelectedFrom2ndNav = navbarSelectedTab?.tabSelectedFrom2ndNav;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleActiveTab = (value) => {
    dispatch(
      setNavbarSelectedTab({
        exploreORmyproject: "myproject",
        tabSelectedFrom2ndNav: value,
      })
    );
  };

  return (
    <>
      {/* selected my ongoing projects / payments / discussions */}
      <Container fluid className="mt-5 mb-4" style={{ transition: "all 0.6s" }}>
        <Row id="myProjectMainContainer">
          <Col
            xs={{ span: 5, order: "first" }}
            // md={{ span: 5, order: "first" }}
            lg={{ span: 4, order: "first" }}
            className="mt-2 ps-3"
            id="myProject1"
          >
            {(showProjectDetails || location?.pathname === "/checkout") && (
              <div
                id="project-detail-backicon"
                onClick={() => dispatch(setShowProjectDetails(null))}
              >
                <MdOutlineKeyboardBackspace size={25} />
              </div>
            )}

            {showProjectDetails || location?.pathname === "/checkout"
              ? "Project details"
              : navbarSelectedTab?.tabSelectedFrom2ndNav === "ongoing"
              ? "All projects"
              : navbarSelectedTab?.tabSelectedFrom2ndNav === "payments"
              ? "Transactions"
              : "Support"}
          </Col>
          <Col
            xs={{ span: 10, order: "last" }}
            // md={{ span: 10, order: "last" }}
            lg={{ span: 4, order: "second" }}
            className="mt-2"
            id="myProject2"
          >
            {showProjectDetails || location?.pathname === "/checkout" ? null : (
              <>
                <div
                  id="myProject2Text"
                  onClick={() => {
                    dispatch(setShowProjectDetails(null));
                    handleActiveTab("ongoing");
                  }}
                >
                  Ongoing{" "}
                  <div
                    id={
                      TabSelectedFrom2ndNav === "ongoing"
                        ? "myProject2Active"
                        : "myProject2Inactive"
                    }
                  />
                </div>
                <div
                  id="myProject2Text"
                  onClick={() => {
                    dispatch(setShowProjectDetails(null));
                    handleActiveTab("payments");
                  }}
                >
                  Payments{" "}
                  <div
                    id={
                      TabSelectedFrom2ndNav === "payments"
                        ? "myProject2Active"
                        : "myProject2Inactive"
                    }
                  />
                </div>
                <div
                  id="myProject2Text"
                  onClick={() => {
                    dispatch(setShowProjectDetails(null));
                    handleActiveTab("discussions");
                  }}
                >
                  Discussions{" "}
                  <div
                    id={
                      TabSelectedFrom2ndNav === "discussions"
                        ? "myProject2Active"
                        : "myProject2Inactive"
                    }
                  />
                </div>
              </>
            )}
          </Col>

          <Col
            xs={{ span: 7, order: "second" }}
            lg={{ span: 4, order: "last" }}
            id="myProject3"
            className="pe-3"
          >
            {/* <Button id="myProject3Button">
              <img src={CreateButton} id="myProject3ButtonCreate" />
              Create a Free Design
            </Button> */}
          </Col>
        </Row>

        {showProjectDetails ||
        navbarSelectedTab?.tabSelectedFrom2ndNav !== "ongoing" ? null : (
          <div id="myProject4" className="mt-3">
            <span className="me-2" id="myProject5" onClick={handleShow}>
              FILTER
            </span>
            <img
              src={FilterImage}
              alt="Filter"
              style={{ width: 15, height: 15 }}
              onClick={handleShow}
            />
            <OngoingProjectsFilterMobile
              placement="bottom"
              show={show}
              handleClose={handleClose}
            />
          </div>
        )}

        <Row style={{ transition: "all 0.15s" }}>
          <Col>
            {TabSelectedFrom2ndNav === "ongoing" ? (
              <Ongoing />
            ) : TabSelectedFrom2ndNav === "payments" ? (
              <Payments />
            ) : (
              <Discussions />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
