import React, { Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./App.css";
import RootRouter from "./navigations/RootRouter";
import RootRouterAfterLogin from "./navigations/RootRouterAfterLogin";
import Sidebar from "./components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import Loader from "./components/Loader/Loader";

const App = () => {
  const dispatch = useDispatch();
  const { token, isProfileCompleted } = useSelector((state) => state.user);

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <div className="cont-visible">
          {token && isProfileCompleted ? (
            <Container fluid>
              <Row>
                <Col xs={12} lg={3} xl={2}>
                  <Sidebar />
                </Col>
                <Col xs={12} lg={9} xl={10} className="px-0">
                  <RootRouterAfterLogin />
                </Col>
              </Row>
            </Container>
          ) : (
            <RootRouter />
          )}
        </div>
      </Suspense>
      <Toaster />
    </div>
  );
};

export default App;
