import React, { useEffect, useState } from "react";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../Utility/Utility";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setSidebarFilterSelectedTab,
} from "../../../Redux/Slice/userSlice";

function useConstruction() {
  const [showDesignDetailsModal, setShowDesignDetailsModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedRoomStyle, setSelectedRoomStyle] = useState({});
  const [searchInput, setSearchInput] = useState("");

  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchCategories = async (token) => {
    try {
      const response = await UserController.getCategories({
        type: "HOME",
        token,
      });

      return response.data.data;
    } catch (error) {
      if (
        error.response?.data?.message === "Invalid token. Please log in again!"
      ) {
        dispatch(logout());
      } else {
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  const fetchProductByCategoryId = async (categoryId, token) => {
    try {
      const response = await UserController.getRoomStyleByCategoryId({
        id: categoryId,
        token,
      });
      console.log("response", response);
      return response?.data?.data?.ourRoomStyle || [];
    } catch (error) {
      console.log("error in product categor id", error);
      if (
        error.response?.data?.message === "Invalid token. Please log in again!"
      ) {
        dispatch(logout());
      } else {
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    refetch: refetchCategories,
  } = useQuery({
    queryKey: ["categories"], // Query key
    queryFn: () => fetchCategories(token), // Fetch function
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
  });

  const {
    data: products,
    isLoading: isProductsLoading,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ["products", selectedCategory],
    queryFn: () => {
      if (categories && categories.length > 0) {
        const categoryId =
          selectedCategory === 0
            ? categories[0]?._id
            : categories[selectedCategory]?._id;
        return fetchProductByCategoryId(categoryId, token);
      }
      return Promise.resolve([]);
    },
    enabled: !!categories && categories.length > 0,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    onError: (error) => {
      console.error("error", error);
    },
  });

  const handleDataUpdate = () => {
    refetchCategories();
    refetchProducts();
  };

  const getFirstTwoWords = (str) => {
    const words = str.split(" ");
    return words.slice(0, 2).join(" ");
  };

  const loadingArray = new Array(6).fill("");

  const SkeltonCategoryLoader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Skeleton
        style={{
          width: 40,
          height: 40,
          borderRadius: "60%",
        }}
      />
      <Skeleton
        style={{
          width: 25,
          borderRadius: "5rem",
          marginTop: 5,
        }}
      />
    </div>
  );

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredProducts = products?.filter((product) =>
    product.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return {
    categories,
    isCategoriesLoading,
    products: filteredProducts,
    isProductsLoading,
    getFirstTwoWords,
    loadingArray,
    SkeltonCategoryLoader,
    showDesignDetailsModal,
    setShowDesignDetailsModal,
    selectedCategory,
    setSelectedCategory,
    noProductsMessage: "No house designs found.",
    handleDataUpdate,
    setSelectedRoomStyle,
    selectedRoomStyle,
    handleSearch,
    searchInput,
    refetchCategories,
    refetchProducts,
    filteredProducts,
  };
}

export default useConstruction;
