import React from "react";
import { Button } from "@material-ui/core";
import { HiOutlineChevronRight } from "react-icons/hi";

export default function FilterButtonComp({ title, selected, onClick }) {
  const getFirstTwoWords = (str) => {
    const words = str?.split(" ");
    return words?.slice(0, 2)?.join(" ");
  };

  return (
    <Button
      fullWidth
      className="mb-3"
      style={{
        backgroundColor: selected ? "#1757FF" : "#F1F4F8",
        color: selected ? "#fff" : "#000",
        width: "100%",
        height: 40,
        borderRadius: 8,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
        fontSize: "0.8rem",
        fontFamily: "DM Sans, sans-serif",
        fontWeight: "400",
        transition: "background-color 0.3s ease, color 0.3s ease",
      }}
      onClick={onClick}
    >
      <span className="me-5">{getFirstTwoWords(title)}</span>
      <HiOutlineChevronRight />
    </Button>
  );
}
