import React, { useEffect, useState } from "react";
import "./ProjectDetails.css";
import { Col, Row, ProgressBar } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { LuChevronRight } from "react-icons/lu";
import { MdOutlineFileDownload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import Brixcycalendermodal from "../../../components/Brixcycalendermodal/Brixcycalendermodal";
import Loader from "../../../components/Loader/Loader";
import BookConsultant from "../components/BookConsultant/BookConsultant";
import ProjectDescription from "../components/ProjectDescription/ProjectDescription";
import PaymentCard from "../../../components/PaymentCard/PaymentCard";
import { RedirectToNewTab } from "../../../components/RedirectToNewTab/RedirectToNewTab";
import { UserController } from "../../../Redux/controllers/UserController";
import interior1 from "../../../assets/Material/interior/interior1.png";
import interior2 from "../../../assets/Material/interior/interior2.png";
import interior3 from "../../../assets/Material/interior/interior3.png";
import interior4 from "../../../assets/Material/interior/interior4.png";
import interior5 from "../../../assets/Material/interior/interior5.png";
import smarthome1 from "../../../assets/Material/smarthome/smarthome1.png";
import smarthome2 from "../../../assets/Material/smarthome/smarthome2.png";
import smarthome3 from "../../../assets/Material/smarthome/smarthome3.png";

// Button and Material Arrays
const buttonData = [
  { value: "interior", text: "Interior Products" },
  { value: "smarthome", text: "Smart Home products" },
  { value: "wooden", text: "Wooden products" },
  { value: "electronics", text: "Electronics Items" },
  { value: "electrical", text: "Electrical Appliances" },
  { value: "hardware", text: "Hardwares" },
];

const materialArray = [
  { name: "Tiles", category: "Interior Products", image: interior1 },
  { name: "Sofa", category: "Interior Products", image: interior2 },
  {
    name: "Digital Lock System",
    category: "Smart Home Products",
    image: interior3,
  },
  { name: "Window", category: "Wooden Products", image: interior4 },
  { name: "Refrigerator", category: "Electronics Items", image: interior5 },
  { name: "Wires", category: "Electrical Appliances", image: smarthome1 },
  { name: "Pipes", category: "Hardwares", image: smarthome2 },
  { name: "Door", category: "Wooden Products", image: smarthome3 },
];

// Main ProjectDetails Component
export default function ProjectDetails({ projectId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user) || {};

  const [showProjectDetails, setShowProjectDetails] = useState(true);
  const [selectedMaterialType, setSelectedMaterialType] = useState(
    buttonData[0]?.value
  );
  const [showConsultModal, setShowConsultModal] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjectDetails = async (id) => {
    try {
      const { data } = await UserController.getProjectById({ id, token });
      setProjectDetails(data.data);
    } catch (err) {
      console.error("Error fetching project details", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) fetchProjectDetails(projectId);
  }, [projectId]);

  const filteredMaterials = materialArray.filter((item) =>
    item?.category?.toLowerCase()?.includes(selectedMaterialType.toLowerCase())
  );

  const showProjectMangerDetails = projectDetails?.projectManager || null;
  const showSalePersonDetails = projectDetails?.salesPerson || null;
  const showQaQcDetails = projectDetails?.qaQcSubAdmin || null;

  // Sub-Components
  const ShowProjectName = React.memo(() => (
    <div
      id="card-bottom-container"
      onClick={() => setShowProjectDetails(!showProjectDetails)}
    >
      <div id="card-title">{projectDetails?.roomStyle?.name}</div>
      <div>
        <LuChevronRight size={17} />
      </div>
    </div>
  ));

  const ShowProjectDetailsCard = React.memo(() => (
    <div
      id="projectdetails-card-container"
      onClick={() => setShowProjectDetails(!showProjectDetails)}
    >
      <div id="projectdetails-card-open-title-container">
        <div id="projectdetails-card-open-title">
          Project ID : #{projectDetails?._id?.slice(-7)}
        </div>
        <FaChevronDown size={17} />
      </div>
      <div id="projectdetails-card-open-body-container">
        <ProgressBar
          now={parseInt(projectDetails?.progressPercentage) || 0}
          className="custom-progress-bar"
        />
        <div id="project-progress-text">
          {parseInt(projectDetails?.progressPercentage) || 0}% Project progress
        </div>

        <div id="onsite-container">
          <div id="onsite-status-label">Status</div>
          <div id="onsite-status-div">
            {projectDetails?.latestProjectStatus}
          </div>
        </div>

        {(showProjectMangerDetails ||
          showQaQcDetails ||
          showSalePersonDetails) && (
          <div id="onsite-container">
            <div id="onsite-status-label">On-Site Staff</div>
            <div id="onsite-status-div">Active</div>
          </div>
        )}
        {showProjectMangerDetails && (
          <div id="project-manager-container">
            <div>
              <div id="project-manger-name">
                {showProjectMangerDetails?.firstName}{" "}
                {showProjectMangerDetails?.lastName} (Manager)
              </div>
              <div id="project-manger-number">
                {showProjectMangerDetails?.phoneNumber}
              </div>
            </div>
            <div id="active-div" />
          </div>
        )}
        {showQaQcDetails && (
          <div id="project-manager-container">
            <div>
              <div id="project-manger-name">
                {showQaQcDetails?.firstName} {showQaQcDetails?.lastName}{" "}
                (Quality)
              </div>
              <div id="project-manger-number">
                {showQaQcDetails?.phoneNumber}
              </div>
            </div>
            <div id="active-div" />
          </div>
        )}
        {showSalePersonDetails && (
          <div id="project-manager-container">
            <div>
              <div id="project-manger-name">
                {showSalePersonDetails?.firstName}{" "}
                {showSalePersonDetails?.lastName} (Sales)
              </div>
              <div id="project-manger-number">
                {showSalePersonDetails?.phoneNumber}
              </div>
            </div>
            <div id="active-div" />
          </div>
        )}
      </div>
    </div>
  ));

  const MaterialCard = React.memo(({ docUrl }) => (
    <div id="material-parent-container">
      <Row>
        <Col xs={12} md={6} id="material-label">
          Materials Used
        </Col>
        <Col />
        <Col xs={12} md={4}>
          <ButtonComponent
            title="Download Material List"
            titleCss={{ fontSize: "12px" }}
            LeftIcon={MdOutlineFileDownload}
            leftIconSize={18}
            onClick={() => RedirectToNewTab(docUrl)}
          />
        </Col>
      </Row>
    </div>
  ));

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col md={4}>
        <div className="mt-4">
          <img
            src={projectDetails?.roomStyle?.images?.[0]}
            id="projectdetails-card-image"
            alt="Project"
          />
        </div>
        {showProjectDetails ? <ShowProjectDetailsCard /> : <ShowProjectName />}
      </Col>
      <Col md={8}>
        <ProjectDescription projectDetails={projectDetails} />
        {projectDetails?.excelFile && (
          <MaterialCard docUrl={projectDetails?.excelFile} />
        )}
        <BookConsultant
          consultantDetails={projectDetails?.consultationDetails}
          setShowConsultModal={setShowConsultModal}
        />
        <PaymentCard
          projectDetails={projectDetails}
          fetchProjectApi={() => fetchProjectDetails(projectId)}
        />
      </Col>
      <Brixcycalendermodal
        show={showConsultModal}
        onHide={() => setShowConsultModal(false)}
        projectId={projectDetails?._id}
        isReconsulting={true}
      />
    </Row>
  );
}
