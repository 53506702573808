import React, { useState } from "react";
import "./Chooseslot.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FiClock } from "react-icons/fi";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { FaEarthAmericas } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { NavConstants } from "../../navigations/NavConstants";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import Utility from "../../Utility/Utility";
import { IoMdArrowRoundBack } from "react-icons/io";

function Chooseslot({
  show,
  onHide,
  roomStyleId,
  date,
  slotId,
  timeSlotId,
  startTime,
  endTime,
  projectId,
  isReconsulting,
}) {
  const { token, userInfo } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const [email, setEmail] = useState(userInfo?.email);
  const [name, setName] = useState(userInfo?.firstName);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    try {
      const data = {
        roomStyleId: roomStyleId,
        date: date,
        slotId: slotId,
        timeSlotId: timeSlotId,
        description: description,
      };
      console.log("Data is......", data);

      const response = await HttpClient.apiCaller(
        `v1/project/consult/book`,
        "POST",
        data,
        token
      );
      setDescription("");
      console.log("re post choose", response);

      navigate(NavConstants.slotscheduled, { state: response?.data?.data });
      Utility.sToast("Meeting Scheduled Successfully !");
    } catch (error) {
      if (error?.response && error?.response?.data) {
        Utility.eToast(
          error?.response?.data?.message ||
            "An error occurred while scheduling the meeting."
        );
      } else {
        Utility.eToast("An error occurred while scheduling the meeting.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReconsultBooking = async () => {
    setLoading(true);
    try {
      const data = {
        projectId: projectId,
        date: date,
        slotId: slotId,
        timeSlotId: timeSlotId,
        description: description,
      };
      console.log("Data is......", data);

      const response = await HttpClient.apiCaller(
        `v1/project/consult/book`,
        "POST",
        data,
        token
      );
      setDescription("");
      console.log("re post choose", response);

      navigate(NavConstants.slotscheduled, { state: response?.data?.data });
      Utility.sToast("Meeting Scheduled Successfully !");
    } catch (error) {
      if (error?.response && error?.response?.data) {
        Utility.eToast(
          error?.response?.data?.message ||
            "An error occurred while scheduling the meeting."
        );
      } else {
        Utility.eToast("An error occurred while scheduling the meeting.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={onHide} fullscreen>
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header> */}
        <Modal.Body id="calendly18">
          <Container>
            <Row>
              <Col md={1} lg={1} sm={12} xs={12}></Col>
              <Col md={10} lg={10} sm={12} xs={12}>
                <div id="chooseslot1">
                  <Row>
                    <Col
                      md={5}
                      lg={5}
                      sm={12}
                      xs={12}
                      className="calendly-column"
                    >
                      <div>
                        <Row>
                          <Col md={1} lg={1} sm={2} xs={2}>
                            <IoMdArrowRoundBack
                              onClick={handleBackClick}
                              id="chooseslot11"
                            />
                          </Col>
                        </Row>
                        <div id="calendly5">
                          {userInfo?.firstName || "N/A"}{" "}
                          {userInfo?.lastName || "N/A"}
                        </div>
                        <div id="calendy6">Bricxy Consultation</div>
                        <Row id="calendly7">
                          <Col md={1} lg={1} sm={2} xs={2}>
                            <div>
                              <FiClock />
                            </div>
                          </Col>
                          <Col md={11} lg={11} sm={10} xs={10}>
                            <div id="calendly8">1 Hr</div>
                          </Col>
                        </Row>
                        <Row id="calendly7">
                          <Col md={1} lg={1} sm={2} xs={2}>
                            <div>
                              <HiOutlineVideoCamera />
                            </div>
                          </Col>
                          <Col md={11} lg={11} sm={10} xs={10}>
                            <div id="calendly8">
                              Web conferencing details provided upon
                              confirmation.
                            </div>
                          </Col>
                        </Row>
                        <Row id="calendly7">
                          <Col md={1} lg={1} sm={2} xs={2}>
                            <div>
                              <SlCalender />
                            </div>
                          </Col>
                          <Col md={11} lg={11} sm={10} xs={10}>
                            <div id="calendly8">
                              {startTime && endTime
                                ? `${startTime} - ${endTime}`
                                : "Time not available"}
                            </div>
                          </Col>
                        </Row>
                        <Row id="calendly7">
                          <Col md={1} lg={1} sm={2} xs={2}>
                            <div>
                              <FaEarthAmericas />
                            </div>
                          </Col>
                          <Col md={11} lg={11} sm={10} xs={10}>
                            <div id="calendly8">India Standard Time</div>
                          </Col>
                        </Row>
                        <div id="calendly9">Join for one-on-one discussion</div>
                      </div>
                    </Col>
                    <Col md={7} lg={7} sm={12} xs={12}>
                      <div id="chooseslot2">Enter Details</div>
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label id="chooseslot3" value={name} disabled>
                            Name *
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="chooseslot4"
                            value={name}
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label id="chooseslot3" value={email} disabled>
                            Email *
                          </Form.Label>
                          <Form.Control
                            type="email"
                            id="chooseslot4"
                            value={email}
                          />
                        </Form.Group>
                      </div>
                      <div>
                        {/* <Button id="chooseslot5">Add Guest</Button> */}
                      </div>
                      <div id="chooseslot6">
                        Please share anything that will help prepare for our
                        meeting.
                      </div>
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            id="chooseslot7"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div id="chooseslot8">
                        By proceeding, you confirm that you have read and agree
                        to <span id="chooseslot9">Bricxy's Terms of Use</span>{" "}
                        and <span id="chooseslot9">Privacy Notice</span>.
                      </div>
                      <div>
                        <Button
                          id="chooseslot10"
                          onClick={
                            isReconsulting ? handleReconsultBooking : save
                          }
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner as="span" animation="border" size="sm" />
                          ) : (
                            "Schedule Event"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={1} lg={1} sm={12} xs={12}></Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default React.memo(Chooseslot);
