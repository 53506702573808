import React, { useEffect, useState } from "react";
import "./Discussions.css";
import { Col, Container, Row } from "react-bootstrap";
import AllChat from "../components/AllChat/AllChat";
import ChatDetails from "../components/ChatDetails/ChatDetails";
import Whatsapp from "../../../assets/Discussion/Whatsapp.png";
import { Button } from "@mui/material";

export default function Discussions() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("getting screen width", screenWidth);

  const isMobileDevice = screenWidth <= 768;
  const handleStartChat = () => {
    window.open("https://web.whatsapp.com/", "_blank");
  };

  return (
    // <Row>
    //   <Col lg={3} md={5}>
    //     <AllChat isMobileDevice={isMobileDevice} />
    //   </Col>
    //   <Col lg={9} md={7}>
    //     <ChatDetails isMobileDevice={isMobileDevice} />
    //   </Col>
    // </Row>
    <Container fluid>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div id="discuss1">
            <img src={Whatsapp} />
          </div>
          <div id="discuss3">
            Feel free to share the details of your issue, and our team will do
            their best to help you.
          </div>
          <div id="discuss2">
            <Button onClick={handleStartChat} id="discuss4">
              Connect With Us
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
