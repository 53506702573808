import { HttpClient } from "./HttpClient";

const sendOtp = (data) => {
  let url = "otp/send";
  return HttpClient.apiCaller(url, "POST", data, null);
};

const verifyOtpAndLogin = (data) => {
  let url = "otp/verify";
  return HttpClient.apiCaller(url, "POST", data, null);
};

const registerDreamHomeRequirements = (item) => {
  let url = "v1/home/add";
  return HttpClient.apiCaller(url, "POST", item?.data, item.token);
};

const fileUpload = (data, onProgress) => {
  let url = "upload/single-upload";
  return HttpClient.uploadApiCaller(url, data, onProgress);
};

const contactUs = (data) => {
  let url = "v1/contact/add";
  return HttpClient.apiCaller(url, "POST", data, null);
};

const updateProfile = (item) => {
  let url = "v1/user/update-profile";
  return HttpClient.apiCaller(url, "patch", item.data, item.token);
};

const joinTeam = (item) => {
  let url = "v1/join-team/add";
  return HttpClient.apiCaller(url, "POST", item.data);
};

const getOurTeam = (page = 1, limit = 10) => {
  console.log("getting value", page);
  let url = `v1/our-team/get?page=${page}&limit=${limit}`;
  return HttpClient.apiCaller(url, "GET");
};

const getTestimonial = (page = 1, limit = 10) => {
  console.log("getting value", page);
  let url = `v1/testimonial/get`;
  return HttpClient.apiCaller(url, "GET");
};

const getCategories = ({ type, token }) => {
  let url = `v1/base-category/get?page=1&limit=10&type=${type || "HOME"}`;
  console.log("getting token", type);
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const getRoomStyleByCategoryId = (item) => {
  console.log("category", item.id);
  let url = `v1/bed-room-style/get-by-category?page=1&limit=10&id=${item.id}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getProductsByCategoryId = (item) => {
  console.log("selected id interior", item?.id);
  let url = `v1/product/get?page=1&limit=100&categoryId=${item?.id}&classId=${item.classId}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getWishlistedProducts = (item) => {
  let url = "v1/user/wishlist";
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getBaseClassCategory = (item) => {
  let url = "v1/base-class";
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const toggleWishList = (item) => {
  let url = "v1/user/wishlist";
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const getProjectByStatus = (item) => {
  let url = `v1/project?page=1&limit=10&status=${item.status}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};
const moreDesignComponent = (item) => {
  console.log("1111", item);
  let url = `v1/bed-room-style/get-recommendation?page=1&limit=10&roomId=${item.roomId}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getSimilarProducts = (item) => {
  let url = `v1/product/get-recommendation?page=1&limit=12&productId=${item.productId}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getProjectById = (item) => {
  let url = `v1/project/${item.id}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const updatePaymentDetails = (item) => {
  let url = `v1/project/submit-payment/${item.id}`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const multiImageUpload = (data, onProgress) => {
  let url = "upload/multiple-upload";
  return HttpClient.uploadApiCaller(url, data, onProgress);
};

const getAllProjectPayments = (item) => {
  let url = `v1/project/payments/get-all`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getMyProfile = (item) => {
  let url = `v1/user/profile/${item.id}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

export const UserController = {
  sendOtp,
  verifyOtpAndLogin,
  registerDreamHomeRequirements,
  fileUpload,
  contactUs,
  updateProfile,
  joinTeam,
  getOurTeam,
  getTestimonial,
  getCategories,
  getProductsByCategoryId,
  getRoomStyleByCategoryId,
  getWishlistedProducts,
  getBaseClassCategory,
  toggleWishList,
  getProjectByStatus,
  moreDesignComponent,
  getSimilarProducts,
  getProjectById,
  updatePaymentDetails,
  multiImageUpload,
  getAllProjectPayments,
  getMyProfile,
};
