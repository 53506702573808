import React, { useState } from "react";
import "./Addcomments.css";
import { Col, Container, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Rating } from "react-simple-star-rating";
import { Button } from "@mui/material";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import Utility from "../../Utility/Utility";
import CircularProgress from "@mui/material/CircularProgress";

function Addcomments({ productId, fetchReviewApi = () => {} }) {
  const { token, userInfo } = useSelector((state) => state?.user);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("Product id is......", productId);

  const validation = () => {
    if (rating === 0) {
      Utility.eToast("Please add Ratings!");
      return false;
    } else if (comment.trim()?.length === 0) {
      Utility.eToast("Please add a review!");
      return false;
    } else {
      return true;
    }
  };

  const save = () => {
    if (validation()) {
      setLoading(true);
      const data = {
        product: productId,
        user: userInfo?._id,
        rating: rating,
        review: comment,
      };
      console.log("Data is......", data);

      HttpClient.apiCaller(`v1/product-review`, "POST", data, token)
        .then((res) => {
          setRating(0);
          setComment("");
          fetchReviewApi();
          Utility.sToast("Feedback added Successfully ! ");
          setLoading(false);
        })
        .catch((error) => {
          Utility.eToast("Something Went Wrong ! ");
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Container fluid>
        <Row id="Addcomment2">
          <div id="Addcomment1">Drop a Review</div>
          <div id="Addcomment3">
            Hey !! You have purchased this product previously.
          </div>
          <div id="Addcomment4">
            <Rating
              size={25}
              label
              transition
              fillColor="green"
              emptyColor="#C0CDE4"
              initialValue={rating}
              onClick={setRating}
            />
          </div>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <div id="add-comment-fields">
                <TextField
                  id="outlined-review"
                  label="Drop a Review"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  margin="normal"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div id="Addcomment5">
            <Button id="Addcomment6" onClick={() => save()} disabled={loading}>
              {/* Submit */}
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(Addcomments);
