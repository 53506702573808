import { combineReducers } from "redux";
import user from "./userSlice";
import wishlist from "./wishListSlice";

const rootReducer = combineReducers({
  user: user,
  wishlist: wishlist,
});

export default rootReducer;
