import moment from "moment";
import { Col, Row } from "react-bootstrap";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import "./BookConsultant.css";
import { RedirectToNewTab } from "../../../../components/RedirectToNewTab/RedirectToNewTab";

const BookConsultant = ({ consultantDetails, setShowConsultModal }) => {
  const currentDate = moment(new Date());

  const upcomingMeeting = consultantDetails?.find((meeting) => {
    const meetingDate = moment(meeting.date);

    return meetingDate.isSameOrAfter(currentDate, "day");
  });

  return (
    <div id="material-parent-container">
      {upcomingMeeting ? (
        <Row>
          <Col id="material-label" md={5}>
            Upcoming Meeting
          </Col>
          <Col>
            <div>
              Date: {moment(upcomingMeeting?.date).format("MMMM Do YYYY")}
            </div>
            <div>
              Time: {upcomingMeeting?.startTime} - {upcomingMeeting?.endTime}
            </div>
            <div>Day: {upcomingMeeting?.weekDay}</div>
            <div
              onClick={
                upcomingMeeting?.link
                  ? () => RedirectToNewTab(upcomingMeeting?.link)
                  : null
              }
            >
              Meeting Link:{" "}
              <span id="meeting-link-text">
                {upcomingMeeting?.link || "Meeting link will update soon"}
              </span>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col id="material-label">Do you have any Query?</Col>
          <Col></Col>
          <Col md={4}>
            <ButtonComponent
              title="Book a Consultation"
              titleCss={{ fontSize: "13px" }}
              onClick={() => setShowConsultModal(true)}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BookConsultant;
