import React, { useState } from "react";
import "./Explore.css";
import Construction from "./Construction/Construction";
import FilterImage from "../../assets/sidebar/filter.svg";
import { Button, Col, Container, Row } from "react-bootstrap";
import CreateButton from "../../assets/Construction/createButton.svg";
import { useDispatch, useSelector } from "react-redux";
import { setNavbarSelectedTab } from "../../Redux/Slice/userSlice";
import Material from "./Material/Material";
import MaterialFilterMobile from "../../components/Sidebar/MaterialFilterComp/MaterialFilterMobile";

export default function Explore() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { navbarSelectedTab } = useSelector((state) => state.user);

  const handleActiveTab = (value) => {
    dispatch(
      setNavbarSelectedTab({
        exploreORmyproject: "explore",
        tabSelectedFrom2ndNav: value,
      })
    );
  };

  return (
    <Container fluid className="mt-5 mb-4" style={{ transition: "all 0.6s" }}>
      <Row id="exploreMainContainer">
        <Col
          xs={{ span: 5, order: "first" }}
          // md={{ span: 3, order: "first" }}
          lg={{ span: 4, order: "first" }}
          className="mt-2 ps-3"
          id="explore1"
        >
          {navbarSelectedTab?.tabSelectedFrom2ndNav === "construction"
            ? "House Designs"
            : navbarSelectedTab?.tabSelectedFrom2ndNav === "material"
            ? "All Materials"
            : "Manage"}
        </Col>
        <Col
          xs={{ span: 10, order: "last" }}
          // md={{ span: 5, order: "last" }}
          lg={{ span: 4, order: "second" }}
          className="mt-2"
          id="explore2"
        >
          <div
            id="explore2Text"
            onClick={() => handleActiveTab("construction")}
          >
            Construction{" "}
            <div
              id={
                navbarSelectedTab?.tabSelectedFrom2ndNav === "construction"
                  ? "explore2Active"
                  : "explore2Inactive"
              }
            />
          </div>
          <div id="explore2Text" onClick={() => handleActiveTab("material")}>
            Material{" "}
            <div
              id={
                navbarSelectedTab?.tabSelectedFrom2ndNav === "material"
                  ? "explore2Active"
                  : "explore2Inactive"
              }
            />
          </div>
          {/* <div id="explore2Text" onClick={() => handleActiveTab("manage")}>
            Manage{" "}
            <div
              id={
                navbarSelectedTab?.tabSelectedFrom2ndNav === "manage" ? "explore2Active" : "explore2Inactive"
              }
            />
          </div> */}
        </Col>
        <Col
          xs={{ span: 7, order: "second" }}
          lg={{ span: 4, order: "last" }}
          id="explore3"
          className="pe-3"
        >
          {/* <Button id="explore3Button">
            <img src={CreateButton} id="explore3ButtonCreate" />
            Create a Free Design
          </Button> */}
        </Col>
      </Row>

      {navbarSelectedTab?.tabSelectedFrom2ndNav === "material" ? (
        <div id="myProject4" className="mt-3">
          <span className="me-2" id="myProject5" onClick={handleShow}>
            FILTER
          </span>
          <img
            src={FilterImage}
            alt="Filter"
            style={{ width: 15, height: 15 }}
            onClick={handleShow}
          />
          <MaterialFilterMobile
            placement="bottom"
            show={show}
            handleClose={handleClose}
          />
        </div>
      ) : null}

      <Row style={{ transition: "all 0.15s" }}>
        <Col>
          {navbarSelectedTab?.tabSelectedFrom2ndNav === "construction" ? (
            <Construction />
          ) : navbarSelectedTab?.tabSelectedFrom2ndNav === "material" ? (
            <Material />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}
