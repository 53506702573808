import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { HiOutlineDownload } from "react-icons/hi";
import moment from "moment";
import "./Paymenthistorymodal.css";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../constants/formatCurrency";

function Paymenthistorymodal({ show, onHide, getpayment }) {
  const handleDownload = (documents) => {
    documents.forEach((url, index) => {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", url.split("/").pop());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 100);
    });
  };

  const columns = [
    {
      name: "Paid On",
      selector: (row) => moment(row?.paidOn).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => formatCurrency(row?.amount),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          style={{
            backgroundColor:
              row?.status === "PENDING"
                ? "#fc6a26"
                : row?.status === "REJECTED"
                ? "#f72b28"
                : "#0B7B69",
            padding: "0.3rem",
            width: "100px",
            textAlign: "center",
            color: "white",
            borderRadius: "0.3rem",
            fontWeight: "500",
          }}
        >
          {row?.status || "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Button onClick={() => handleDownload(row?.documents)}>
          <HiOutlineDownload id="paymenthistorymodal2" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div id="paymenthistorymodal1">Payment History</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable columns={columns} data={getpayment} pagination />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default React.memo(Paymenthistorymodal);
