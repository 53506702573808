import { HttpClient } from "../Redux/controllers/HttpClient";
const CDN_URL = HttpClient.CDN_URL;

export const IMAGES = {
  home: {
    backArrow: `${CDN_URL}/assets/Home/backArrow.png`,
  },
  logo: {
    logo: `${CDN_URL}/assets/Logo/BrixcylogoNew.png`,
  },
  banner: {
    house1: `${CDN_URL}/assets/Home/house20.jpg`,
    house2: `${CDN_URL}/assets/Home/house21.jpg`,
    house3: `${CDN_URL}/assets/Home/house22.jpg`,
    house4: `${CDN_URL}/assets/Home/house11.jpg`,
  },
  wall: {
    wall1: `${CDN_URL}/assets/Home/wall13.png`,
    wall2: `${CDN_URL}/assets/Home/wall2.png`,
    wall3: `${CDN_URL}/assets/Home/wall3.png`,
    wall10: `${CDN_URL}/assets/Home/wall10.png`,
    // wall18: `${CDN_URL}/assets/Home/plumbing1.png`,
    wall11: `${CDN_URL}/assets/Home/fire1.png`,
    wall14: `${CDN_URL}/assets/Home/electric6.png`,
    wall15: `${CDN_URL}/assets/Home/window7.png`,
    wall16: `${CDN_URL}/assets/Home/window2.png`,
    wall17: `${CDN_URL}/assets/Home/window1.png`,
  },
  plumbing1: {
    plumbing1: `${CDN_URL}/assets/Home/plumbing1.png`,
  },

  whybricxy: {
    whybricxy1: `${CDN_URL}/assets/Home/whybricxy1.jpg`,
    whybricxyvideo1: `${CDN_URL}/assets/Home/video1.mp4`,
  },
  partner: {
    partner1: `${CDN_URL}/assets/Partners/person.svg`,
    partner2: `${CDN_URL}/assets/Partners/plumber.svg`,
    partner3: `${CDN_URL}/assets/Partners/carpenter.svg`,
    partner4: `${CDN_URL}/assets/Partners/painter.svg`,
    partner5: `${CDN_URL}/assets/Partners/elctrician.svg`,
    partner6: `${CDN_URL}/assets/Partners/sitemanager.svg`,
    partner7: `${CDN_URL}/assets/Partners/hardware.svg`,
    partner8: `${CDN_URL}/assets/Partners/mechanic.svg`,
    partner9: `${CDN_URL}/assets/Partners/designer.svg`,
  },
  projects: {
    profile1: `${CDN_URL}/assets/Projects/profile1.svg`,
    profile2: `${CDN_URL}/assets/Projects/profile2.svg`,
  },
  commercial: {
    commercial1: `${CDN_URL}/assets/Commercial/villa.svg`,
    commercial2: `${CDN_URL}/assets/Commercial/apartment.svg`,
    commercial3: `${CDN_URL}/assets/Commercial/flats.svg`,
    commercial4: `${CDN_URL}/assets/Commercial/mega.svg`,
  },
  brands: {
    tata: `${CDN_URL}/assets/Brands/Tata.svg`,
    rod: `${CDN_URL}/assets/Brands/Rod.svg`,
    pvc: `${CDN_URL}/assets/Brands/Pvc.svg`,
    godrej: `${CDN_URL}/assets/Brands/Godrej.svg`,
    brick: `${CDN_URL}/assets/Brands/Brick.svg`,
    wire: `${CDN_URL}/assets/Brands/Wire.svg`,
    asianpaints: `https://cdn.bricxy.com/assets/Brands/Asian+Paints.svg`,
  },
  fire: {
    fire1: `${CDN_URL}/assets/Home/fire1.png`,
  },
  Door: {
    Door3: `${CDN_URL}/assets/Home/Door3.png`,
    Door5: `${CDN_URL}/assets/Home/Door5.png`,
  },
  DisplacementsContourImage: {
    DisplacementsContourImage: `${CDN_URL}/assets/Home/displacementsContourImage.svg`,
  },
  BulbOnImage: {
    BulbOnImage: `${CDN_URL}/assets/Home/bulbOnOffImage.png`,
  },
  BulbOffImage: {
    BulbOffImage: `${CDN_URL}/assets/Home/bulbOnOffImage.png`,
  },
  smartHomeServices: {
    SmartHomeServices1: `${CDN_URL}/assets/Home/smartHomeServices1.png`,
    SmartHomeServices2: `${CDN_URL}/assets/Home/smartHomeServices2.png`,
    SmartHomeServices3: `${CDN_URL}/assets/Home/smartHomeServices3.png`,
  },
  team: {
    team1: `${CDN_URL}/assets/Aboutus/teams.svg`,
  },
  story: {
    story1: `${CDN_URL}/assets/Aboutus/meeting1.jpg`,
    story2: `${CDN_URL}/assets/Aboutus/meeting3.jpg`,
    story3: `${CDN_URL}/assets/Aboutus/meeting3.jpg`,
    story4: `${CDN_URL}/assets/Aboutus/meeting4.jpg`,
  },
  meeting: {
    meeting5: `${CDN_URL}/assets/Aboutus/meeting6.svg`,
    meeting6: `${CDN_URL}/assets/Aboutus/meeting3.jpg`,
    wall1: `${CDN_URL}/assets/Home/wall13.png`,
    wall2: `${CDN_URL}/assets/Home/wall2.png`,
    wall3: `${CDN_URL}/assets/Home/wall3.png`,
    wall10: `${CDN_URL}/assets/Home/wall10.png`,
  },
  window: {
    window1: `${CDN_URL}/assets/Home/window1.png`,
    window2: `${CDN_URL}/assets/Home/window2.png`,
    window3: `${CDN_URL}/assets/Home/window3.png`,
    window4: `${CDN_URL}/assets/Home/windo4.png`,
    window5: `${CDN_URL}/assets/Home/window5.png`,
    window6: `${CDN_URL}/assets/Home/window6.webp`,
    window7: `${CDN_URL}/assets/Home/window7.png`,
    window8: `${CDN_URL}/assets/Home/window8.png`,
    window9: `${CDN_URL}/assets/Home/window9.png`,
    window10: `${CDN_URL}/assets/Home/window10.png`,
    window11: `${CDN_URL}/assets/Home/window11.png`,
    window13: `${CDN_URL}/assets/Home/window13.png`,
    window14: `${CDN_URL}/assets/Home/window14.png`,
  },
  electric: {
    electric1: `${CDN_URL}/assets/Home/electric1.png`,
    electric3: `${CDN_URL}/assets/Home/elecric3.png`,
    electric5: `${CDN_URL}/assets/Home/electric5.png`,
    electric6: `${CDN_URL}/assets/Home/electric6.png`,
  },
  BrixcyLogo: {
    BrixcyLogo: `${CDN_URL}/assets/Contact us/MapImg.svg`,
  },
  fire: {
    fire1: `${CDN_URL}/assets/Home/fire1.png`,
  },
  door: {
    door3: `${CDN_URL}/assets/Home/door3.png`,
    door5: `${CDN_URL}/assets/Home/door5.png`,
  },
  displacementsContourImage: {
    displacementsContourImage: `${CDN_URL}/assets/Home/displacementsContourImage.svg`,
  },
  bulbOnImage: {
    bulbOnImage: `${CDN_URL}/assets/Home/bulbOnOffImage.png`,
  },
  bulbOffImage: {
    bulbOffImage: `${CDN_URL}/assets/Home/bulbOnOffImage.png`,
  },

  brixcyLogo: {
    brixcyLogo: `${CDN_URL}/assets/Contact+us/MapImg.svg`,
    house2: `${CDN_URL}/assets/Home/house21.jpg`,
    house3: `${CDN_URL}/assets/Home/house22.jpg`,
    house4: `${CDN_URL}/assets/Home/house11.jpg`,
  },
  brands: {
    tata: `${CDN_URL}/assets/Brands/Tata.svg`,
    rod: `${CDN_URL}/assets/Brands/Rod.svg`,
    pvc: `${CDN_URL}/assets/Brands/Pvc.svg`,
    godrej: `${CDN_URL}/assets/Brands/Godrej.svg`,
    asianpaints: `${CDN_URL}/assets/Brands/AsianPaints.svg`,
    brick: `${CDN_URL}/assets/Brands/Brick.svg`,
    wire: `${CDN_URL}/assets/Brands/Wire.svg`,
  },
  banks: {
    bank1: `${CDN_URL}/assets/Bank/SBI2.png`,
    bank2: `${CDN_URL}/assets/Bank/KOTAK.png`,
    bank3: `${CDN_URL}/assets/Bank/ICICI.png`,
    bank4: `${CDN_URL}/assets/Bank/HDFC.png`,
  },
  brixcyinfo: {
    house1: `${CDN_URL}/assets/Home/houseCuttingImage400.svg`,
  },
  tiles: {
    tileheader1: `${CDN_URL}/assets/Home/Tileheader1.png`,
    tileheader2: `${CDN_URL}/assets/Home/Tileheader2.png`,
    tileheader3: `${CDN_URL}/assets/Home/Tileheader3.png`,
    tileheader4: `${CDN_URL}/assets/Home/Tileheader4.png`,
    tileheader5: `${CDN_URL}/assets/Home/Tileheader5.png`,
    Tileheader6: `${CDN_URL}/assets/Home/Tileheader1.png`,
    tile1: `${CDN_URL}/assets/Home/tile21.jpg`,
    tile2: `${CDN_URL}/assets/Home/tile9.jpg`,
    tile3: `${CDN_URL}/assets/Home/tile20.jpg`,
    tile4: `${CDN_URL}/assets/Home/tile9.jpg`,
    tile5: `${CDN_URL}/assets/Home/tile14.jpg`,
    tile6: `${CDN_URL}/assets/Home/tile7.jpg`,
    tile7: `${CDN_URL}/assets/Home/tile12.jpg`,
    tile8: `${CDN_URL}/assets/Home/tile9.jpg`,
    tile9: `${CDN_URL}/assets/Home/tile13.jpg`,
    tile10: `${CDN_URL}/assets/Home/tile7.jpg`,
    tile11: `${CDN_URL}/assets/Home/tile9.jpg`,
    tile12: `${CDN_URL}/assets/Home/tile9.jpg`,
    tile13: `${CDN_URL}/assets/Home/tile12.jpg`,
    tile14: `${CDN_URL}/assets/Home/tile13.jpg`,
    tile15: `${CDN_URL}/assets/Home/tile14.jpg`,
    tile16: `${CDN_URL}/assets/Home/tile15.jpg`,
    tile17: `${CDN_URL}/assets/Home/wood2.jpg`,
    tile18: `${CDN_URL}/assets/Home/wood2.jpg`,
    tile19: `${CDN_URL}/assets/Home/wood6.jpg`,
    tile20: `${CDN_URL}/assets/Home/wood6.jpg`,
    tile21: `${CDN_URL}/assets/Home/tile12.jpg`,
    tile22: `${CDN_URL}/assets/Home/tile13.jpg`,
    tile23: `${CDN_URL}/assets/Home/tile12.jpg`,
    tile24: `${CDN_URL}/assets/Home/tile14.jpg`,
  },
};
