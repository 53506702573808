import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LuMenuSquare } from "react-icons/lu";
import LocationIcon from "../../assets/UserHeader/locationIcon.svg";
import WishListIcon from "../../assets/UserHeader/wishlistIcon.svg";
import ProfileImg from "../../assets/UserHeader/profileImg.svg";
import { IoMdLogOut } from "react-icons/io";

import "./HeaderAfterLogin.css";
import logo from "../../assets/UserHeader/BrixcylogoNew.png";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setMyAccountSelectedTab,
  setNavbarSelectedTab,
  setSidebarFilterSelectedTab,
  setToken,
  setUserInfo,
} from "../../Redux/Slice/userSlice";
import MyAccountSidebar from "../Sidebar/MyAccountSidebar/MyAccountSidebar";
import { NavConstants } from "../../navigations/NavConstants";
import { MyAccountData } from "../Sidebar/MyAccountSidebar/MyAccountData";
import { UserController } from "../../Redux/controllers/UserController";

const MobileViewHeaderComp = ({ name, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const { liveLatLongAddress, userInfo, token } = useSelector(
    (state) => state.user
  );
  const { profileImg, _id } = userInfo || {};

  useEffect(() => {
    fetchMyProfile();
  }, []);

  const fetchMyProfile = async () => {
    try {
      const response = await UserController.getMyProfile({ id: _id, token });
      dispatch(setUserInfo(response.data.data.user));
    } catch (error) {
      console.error("error in fetchMyProfile", error);
    }
  };

  const handleClose = () => {
    setShow(false);
    window.scrollTo({
      top: 0,
      right: 0,
      behavior: "smooth",
    });
  };

  const handleShow = () => setShow(true);

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(logout());
      navigate("/");
    }
  };

  return (
    <>
      <div onClick={handleShow} className="me-2">
        <LuMenuSquare size={35} />
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to={NavConstants.home}>
              <img src={logo} alt="Logo" id="logo" onClick={handleClose} />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            id="mobile-profile-container"
            // onClick={() => {
            //   handleClose();
            //   navigate(NavConstants.myproject);
            //   dispatch(
            //     setNavbarSelectedTab({
            //       exploreORmyproject: "myproject",
            //       tabSelectedFrom2ndNav: "myaccount",
            //     })
            //   );
            //   dispatch(
            //     setSidebarFilterSelectedTab({
            //       ongoingFilterTab: "ALL",
            //       materialFilterTab: "interiorDesignMaterials",
            //     })
            //   );
            // }}
          >
            <div id="profile-text-div">
              {profileImg ? (
                <img src={profileImg} alt="profileImg" id="profile-text-div" />
              ) : (
                userInfo?.firstName?.slice(0, 1)?.toUpperCase()
              )}
            </div>
            {/* <div id="profile-name-text">Ryan</div> */}
            <div id="profile-name-text" onClick={handleLogout}>
              <IoMdLogOut />
            </div>
          </div>

          {/* for my account sidebar */}
          <MyAccountSidebar
            handleClose={() => {
              navigate(NavConstants.myaccount);
              handleClose();
            }}
          />

          <div id="location-div">
            <Image src={LocationIcon} className="location-icon" />
            {/* <div id="line-div" /> */}
            <div id="border-line-div">
              <div id="location-label">
                My Location
                {/* <Image src={DropdownIcon} /> */}
              </div>
              <div id="location-address-text">
                {liveLatLongAddress &&
                  liveLatLongAddress?.address?.substring(0, 30) + "..."}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const HeaderAfterLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { liveLatLongAddress, userInfo } = useSelector((state) => state.user);
  const { profileImg } = userInfo || {};
  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(logout());
      navigate(NavConstants.home);
    }
  };

  const exploreORmyproject = useSelector(
    (state) => state.user?.navbarSelectedTab?.exploreORmyproject
  );
  const selectedValue = useSelector(
    (state) => state.user?.sidebarFilterSelectedTab?.materialFilterTab
  );

  useEffect(() => {
    if (exploreORmyproject !== location?.pathname) {
      if (location?.pathname === "/myproject") {
        dispatch(
          setNavbarSelectedTab({
            exploreORmyproject: "myproject",
            tabSelectedFrom2ndNav: "ongoing",
          })
        );
      } else if (location?.pathname === "/") {
        dispatch(
          setNavbarSelectedTab({
            exploreORmyproject: "explore",
            tabSelectedFrom2ndNav: "construction",
          })
        );
      } else if (location?.pathname === "/myaccount") {
        dispatch(
          setNavbarSelectedTab({
            exploreORmyproject: "",
            tabSelectedFrom2ndNav: "myaccount",
          })
        );
      } else if (location?.pathname === "/wishlist") {
        dispatch(
          setNavbarSelectedTab({
            exploreORmyproject: "",
            tabSelectedFrom2ndNav: "myaccount",
          })
        );
        dispatch(setMyAccountSelectedTab("mywishlist"));
      }
    }
  }, [location?.pathname]);

  return (
    <Container fluid>
      {/* header for webview */}
      <Row className="pt-4" id="header1">
        <Col className="d-flex align-items-center">
          <Navbar.Brand>
            <div id="location-div">
              <Image src={LocationIcon} id="drop-down-menu-icon" />
              <div id="line-div" />
              <div>
                <div id="location-label">
                  My Location
                  {/* <Image src={DropdownIcon} /> */}
                </div>
                <div id="location-address-text">
                  {liveLatLongAddress &&
                    liveLatLongAddress?.address?.substring(0, 30) + "..."}
                </div>
              </div>
            </div>
          </Navbar.Brand>
        </Col>
        <Col md={3}>
          <div id="chooseTabContainer">
            <div
              id={
                location?.pathname == NavConstants.home
                  ? "activeTabContainer"
                  : "inactiveTabContainer"
              }
              onClick={() => {
                navigate(NavConstants.home);
                dispatch(
                  setNavbarSelectedTab({
                    exploreORmyproject: "explore",
                    tabSelectedFrom2ndNav: "construction",
                  })
                );
                dispatch(
                  setSidebarFilterSelectedTab({
                    ongoingFilterTab: "ALL",
                    materialFilterTab: selectedValue,
                  })
                );
              }}
            >
              Explore
            </div>
            <div
              id={
                location?.pathname === NavConstants.myproject
                  ? "activeTabContainer"
                  : "inactiveTabContainer"
              }
              onClick={() => {
                navigate(NavConstants.myproject);
                dispatch(
                  setNavbarSelectedTab({
                    exploreORmyproject: "myproject",
                    tabSelectedFrom2ndNav: "ongoing",
                  })
                );
                dispatch(
                  setSidebarFilterSelectedTab({
                    ongoingFilterTab: "ALL",
                    materialFilterTab: selectedValue,
                  })
                );
              }}
            >
              My Project
            </div>
          </div>
        </Col>
        <Col>
          <div id="wishList-cart-profile-container">
            {/* <div
              onClick={() => {
                navigate(NavConstants.myaccount);
                dispatch(
                  setNavbarSelectedTab({
                    exploreORmyproject: "",
                    tabSelectedFrom2ndNav: "myaccount",
                  })
                );
                dispatch(setMyAccountSelectedTab(MyAccountData[1].value));
                dispatch(
                  setSidebarFilterSelectedTab({
                    ongoingFilterTab: "ALL",
                    materialFilterTab: selectedValue,
                  })
                );
              }}
            >
              <img src={WishListIcon} id="wishlist-icon" />
            </div> */}
            {/* <div id="cart-container">
              <img src={CartIcon} />
              <div id="cart-text">Cart</div>
            </div> */}
            <div
              id="profile-container"
              onClick={() => {
                navigate(NavConstants.myaccount);
                dispatch(
                  setNavbarSelectedTab({
                    exploreORmyproject: "",
                    tabSelectedFrom2ndNav: "myaccount",
                  })
                );
                dispatch(setMyAccountSelectedTab(MyAccountData[0].value));
                dispatch(
                  setSidebarFilterSelectedTab({
                    ongoingFilterTab: "ALL",
                    materialFilterTab: selectedValue,
                  })
                );
              }}
            >
              {profileImg ? (
                <img src={profileImg} alt="profileImg" id="profile-img" />
              ) : (
                <div id="no-profile">
                  {userInfo?.firstName?.slice(0, 1)?.toUpperCase()}
                </div>
              )}
              <div id="profile-name-text">
                {userInfo?.firstName && userInfo?.firstName}
              </div>
            </div>
            <div
              id="profile-name-text"
              style={{ cursor: "pointer" }}
              onClick={() => handleLogout()}
            >
              <IoMdLogOut size={20} />
            </div>
          </div>
        </Col>
      </Row>

      {/* header for mobile view  */}
      <div id="mobileViewHeaderAfterLogin">
        <Link to={NavConstants.home}>
          <img
            src={logo}
            alt="Logo"
            id="logo"
            onClick={() => navigate(NavConstants.home)}
          />
        </Link>
        <div id="chooseTabContainer">
          <div
            id={
              location?.pathname == NavConstants.home
                ? "activeTabContainer"
                : "inactiveTabContainer"
            }
            onClick={() => {
              navigate(NavConstants.home);
              dispatch(
                setNavbarSelectedTab({
                  exploreORmyproject: "explore",
                  tabSelectedFrom2ndNav: "construction",
                })
              );
              dispatch(
                setSidebarFilterSelectedTab({
                  ongoingFilterTab: "ALL",
                  materialFilterTab: selectedValue,
                })
              );
            }}
          >
            Explore
          </div>
          <div
            id={
              location?.pathname === NavConstants.myproject
                ? "activeTabContainer"
                : "inactiveTabContainer"
            }
            onClick={() => {
              navigate(NavConstants.myproject);
              dispatch(
                setNavbarSelectedTab({
                  exploreORmyproject: "myproject",
                  tabSelectedFrom2ndNav: "ongoing",
                })
              );
              dispatch(
                setSidebarFilterSelectedTab({
                  ongoingFilterTab: "ALL",
                  materialFilterTab: selectedValue,
                })
              );
            }}
          >
            My Project
          </div>
        </div>

        {["end"].map((placement, idx) => (
          <MobileViewHeaderComp
            key={idx}
            placement={placement}
            name={placement}
          />
        ))}
      </div>
    </Container>
  );
};

export default HeaderAfterLogin;
