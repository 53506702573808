import {
  MdHelpOutline,
  MdOutlinePersonOutline,
  MdOutlinePrivacyTip,
} from "react-icons/md";
import { FiHeart } from "react-icons/fi";

export const MyAccountData = [
  {
    name: "My Account",
    value: "myaccount",
    icon: MdOutlinePersonOutline,
  },
  // {
  //     name: "My Wishlists",
  //     value: "mywishlist",
  //     icon: FiHeart
  // },
  // {
  //     name: "Help Center",
  //     value: "helpcenter",
  //     icon: MdHelpOutline
  // },
  {
    name: "FAQs",
    value: "faqs",
    icon: MdHelpOutline,
  },
  // {
  //     name: "Privacy Policy",
  //     value: "privacypolicy",
  //     icon: MdOutlinePrivacyTip
  // },
  // {
  //     name: "Terms & Conditions",
  //     value: "terms",
  //     icon: MdOutlinePrivacyTip
  // },
];
