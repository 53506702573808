import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import "./Loader.css";
import BricxyLogo from "../../assets/Logo/BrixcylogoNew.png";

function Loader() {
  return (
    <Modal
      show={true}
      size="xl"
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      className="loader1"
    >
      <div className="loader-container">
        <div className="spinner">
          <img src={BricxyLogo} alt="Logo" className="logo" />
        </div>
      </div>
    </Modal>
  );
}

export default memo(Loader);
