import { Offcanvas } from "react-bootstrap";
import FilterButtonComp from "../../FilterButtonComp/FilterButtonComp";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarFilterSelectedTab } from "../../../Redux/Slice/userSlice";
import { useQuery } from "@tanstack/react-query";
import Utility from "../../../Utility/Utility";
import { UserController } from "../../../Redux/controllers/UserController";

// filter off canvas for mobile view
export default function MaterialFilterMobile({ show, handleClose, ...props }) {
  const dispatch = useDispatch();
  const selectedValue = useSelector(
    (state) => state.user?.sidebarFilterSelectedTab?.materialFilterTab
  );
  const { token } = useSelector((state) => state.user);

  console.log("selected value", selectedValue);

  const fetchCategories = async () => {
    try {
      const response = await UserController.getCategories({
        type: "INTERIOR",
        token,
      });
      console.log("sidebar", response);
      const categories = response.data.data.ourCategory;

      if (categories?.length > 0) {
        dispatch(
          setSidebarFilterSelectedTab({
            ongoingFilterTab: "ALL",
            materialFilterTab: categories[0],
            searchMaterialFilterValue: "",
          })
        );
      }
      return categories;
    } catch (error) {
      Utility.eToast(
        error.response?.data?.message ||
          error.message ||
          "Oops! Something went wrong"
      );
    }
  };

  const { data: buttonData } = useQuery({
    queryKey: ["categoriesInterior"], // Query key
    queryFn: fetchCategories, // Fetch function
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    onError: (error) => {
      console.error("error", error);
    },
  });

  console.log("button data material filter", buttonData);

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      {...props}
      style={{ height: 400 }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Category</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {buttonData?.map((item, i) => (
          <FilterButtonComp
            key={i}
            title={item?.categoryName}
            selected={
              item?.categoryName === selectedValue?.categoryName && "true"
            }
            onClick={() => {
              dispatch(
                setSidebarFilterSelectedTab({
                  ongoingFilterTab: "ALL",
                  materialFilterTab: item,
                  searchMaterialFilterValue: "",
                })
              );
              handleClose();
            }}
          />
        ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
