import React, { useRef, useState } from "react";
import "./MyProfile.css";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import LinearGradientBg from "../../../../../assets/MyProfile/linearBg.svg";
import Person from "../../../../../assets/MyProfile/person.svg";
import FreeDesignImage from "../../../../../assets/MyProfile/freeDesignImage.svg";
import { GrEdit } from "react-icons/gr";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserInfo,
  UpdateProfile,
} from "../../../../../Redux/Slice/userSlice";
import Utility from "../../../../../Utility/Utility";
import { UserController } from "../../../../../Redux/controllers/UserController";

export default function MyProfile() {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const { userInfo, token } = useSelector((state) => state?.user);
  const { profileImg: profilePic = "" } = userInfo || {};
  const userId = userInfo?._id;
  const [phoneNumber, setPhoneNumber] = useState(userInfo?.phoneNumber);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  console.log("user info", userInfo);

  const clickEdit = () => {
    fileInputRef.current.click();
  };

  const openFile = (event) => {
    const file = event.target.files[0];
    handleUploadFile(file);
    console.log(file);
  };

  const [profileImg, setProfileImg] = useState(profilePic);
  const [email, setEmail] = useState(userInfo?.email);
  const [name, setName] = useState(userInfo?.firstName);
  const [isProfileUploading, setIsProfileUploading] = useState(false);

  const handleUploadFile = async (selectedFile) => {
    try {
      setIsProfileUploading(true);
      var data = new FormData();
      data.append("img", selectedFile);

      const response = await UserController.fileUpload(data, (onProgress) => {
        console.log("upload progress: " + onProgress);
      });

      console.log("response file upload", response?.data?.img);
      setProfileImg(response?.data?.img);
    } catch (err) {
      Utility.eToast(
        err?.response?.data?.response?.message ||
          err.message ||
          "Sorry, Couldn't upload"
      );
    } finally {
      setIsProfileUploading(false);
    }
  };

  const handleUpdateMyprofile = () => {
    setIsBtnLoading(true);
    const data = {
      email: email,
      firstName: name,
      userId: userId,
      profileImg: profileImg,
    };

    dispatch(UpdateProfile({ data, token }))
      .unwrap()
      .then((res) => {
        console.log("response of update Profile", res?.message);
        dispatch(setUserInfo(res?.data?.user));
        Utility.sToast(res?.message);
      })
      .catch((error) => {
        console.log("error of update Profile", error);
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      })
      .finally(() => {
        setIsBtnLoading(false);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div id="myprofile1" className="mb-5">
            <img src={LinearGradientBg} alt="Image" id="myprofile2" />
            {isProfileUploading ? (
              <div id="myprofile14">
                <Spinner animation="border" size="sm" variant="light" />
              </div>
            ) : profileImg ? (
              <img src={profileImg} alt="image" id="myprofile3" />
            ) : (
              <div id="myprofile14">
                {userInfo?.firstName?.slice(0, 1)?.toUpperCase()}
              </div>
            )}
          </div>
          <GrEdit id="myprofile11" onClick={clickEdit} />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={openFile}
            accept="image/*"
          />
          <div id="myprofile12">
            <Button
              id="myprofile13"
              onClick={handleUpdateMyprofile}
              disabled={isBtnLoading}
            >
              Save Changes{" "}
              {isBtnLoading && (
                <div id="btn-loader">
                  <Spinner size="sm" variant="primary" />
                </div>
              )}
            </Button>
          </div>
          <Row id="myprofile8">
            <Col md={5} lg={5} sm={12} xs={12}>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label id="myprofile9">Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="myprofile10"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md={5} lg={5} sm={12} xs={12}>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label id="myprofile9">Email id</Form.Label>
                  <Form.Control
                    type="text"
                    id="myprofile10"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md={5} lg={5} sm={12} xs={12}>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label id="myprofile9">Contact number</Form.Label>
                  <Form.Control
                    type="text"
                    id="myprofile10"
                    value={phoneNumber}
                    disabled
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>

          {/* <div className="mt-4" id="myprofile5Main">
            <div id="myprofile5">Continue where you left off</div>
            <img src={FreeDesignImage} alt="Image" id="myprofile6" />
            <div id="myprofile5">
              We provide a free consultation without design team at our
              experience
              <br />
              center Let’s get started !
            </div>
            <Button id="myprofile7">Book Free Design Session</Button>
          </div> */}
        </Col>
      </Row>
    </Container>
  );
}
