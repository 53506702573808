import React, { useState } from "react";
import "./MaterialsComp.css";
import { Col, Row } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa";
import { MdFavorite } from "react-icons/md";
import MaterialdetailsModal from "../../../../../components/MaterialdetailsModal/MaterialdetailsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  GetWishListAsync,
  ToggleWishlistAsync,
} from "../../../../../Redux/Slice/wishListSlice";
import Utility from "../../../../../Utility/Utility";

export default function MaterialsComp({ data }) {
  const [showMaterialDetailsModal, setShowMaterialDetailsModal] =
    useState(false);
  const [materialDetails, setMaterialDetails] = useState({});

  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((st) => st?.user);
  const { isToggleWishListLoading, materialWishList } = useSelector(
    (st) => st?.wishlist
  );

  const isWishlistedFun = (id) => {
    let wishlistIds = materialWishList?.map((item) => item?._id);
    console.log("is in w");
    return wishlistIds.includes(id);
  };

  console.log("product ===============>", data);

  const handleToggleWishlist = (item) => {
    dispatch(
      ToggleWishlistAsync({
        token: token,
        data: {
          productId: item?._id,
          userId: userInfo?._id,
        },
      })
    )
      .then((res) => {
        // Utility.sToast(res?.payload?.message);
        dispatch(
          GetWishListAsync({
            token: token,
            userId: userInfo?._id,
          })
        );
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <>
      <Row>
        {data?.length > 0 ? (
          data?.map((item, index) => (
            <Col key={index} md={4} lg={4} xl={3} xxl={2} sm={6} xs={12}>
              <div id="standard1">
                <div id="standard2">
                  {item?.name?.substring(0, 20)}
                  {/* <span
                    style={{
                      cursor: isToggleWishListLoading ? "progress" : "pointer",
                    }}
                    onClick={() => handleToggleWishlist(item)}
                    id={`${
                      isWishlistedFun(item?._id)
                        ? "wishlisted-heart-icon"
                        : "heartIcon"
                    }`}
                  >
                    {isWishlistedFun(item?._id) ? (
                      <MdFavorite size={23} />
                    ) : (
                      <FaRegHeart size={21} />
                    )}
                  </span> */}
                </div>
                <div id="standard3">₹{item?.price}</div>
                <div
                  id="standard5"
                  onClick={() => {
                    setMaterialDetails(item);
                    setShowMaterialDetailsModal(true);
                  }}
                >
                  <img
                    src={item?.productImages[0]}
                    alt={item?.description}
                    id="standard4"
                  />
                </div>
              </div>
            </Col>
          ))
        ) : (
          <div>No materials found.</div>
        )}
      </Row>
      {showMaterialDetailsModal && (
        <MaterialdetailsModal
          show={showMaterialDetailsModal}
          handleClose={() => setShowMaterialDetailsModal(false)}
          materialDetails={materialDetails}
        />
      )}
    </>
  );
}
