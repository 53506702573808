import React, { useState } from "react";
import "./Header.css";
import { Col, Container, Offcanvas, Row, Button } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import logo from "../../assets/Logo/BrixcylogoNew.png";
import { LuMenuSquare } from "react-icons/lu";
import { NavConstants } from "../../navigations/NavConstants";
import Headroom from "react-headroom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdLogOut } from "react-icons/io";
import { logout, setToken } from "../../Redux/Slice/userSlice";
import { IMAGES } from "../../constants/images";

const MobileViewHeaderComp = ({ name, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(logout());
      navigate("/");
    }
  };

  const handleClose = () => {
    setShow(false);
    window.scrollTo({
      top: 0,
      right: 0,
      behavior: "smooth",
    });
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div onClick={handleShow} className="me-2">
        <LuMenuSquare size={35} />
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/" onClick={handleClose}>
              <img src={IMAGES.logo.logo} alt="Logo" id="logo" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div id="offcanvasHeader3">
            <nav className="offcanvasNavbar">
              <ul className="offcanvasNavbarList">
                <li className="offcanvasNavbarItem">
                  <NavLink
                    to="/"
                    exact
                    activeClassName="active"
                    onClick={handleClose}
                  >
                    HOME
                  </NavLink>
                </li>
                {/* <li className="offcanvasNavbarItem">
                  <NavLink
                    to="/explore"
                    activeClassName="active"
                    onClick={handleClose}
                  >
                    EXPLORE
                  </NavLink>
                </li> */}
                <li className="offcanvasNavbarItem">
                  <NavLink
                    to="/partner"
                    activeClassName="active"
                    onClick={handleClose}
                  >
                    PARTNER
                  </NavLink>
                </li>
                <li className="offcanvasNavbarItem">
                  <NavLink
                    to="/whybricxy"
                    activeClassName="active"
                    onClick={handleClose}
                  >
                    WHY BRICXY
                  </NavLink>
                </li>
              </ul>
            </nav>

            {token ? (
              <div onClick={handleLogout} id="offcanvasNavbarHeader2">
                <IoMdLogOut size={20} />
              </div>
            ) : (
              <div id="offcanvasNavbarHeader2">
                <Button
                  variant="contained"
                  id="loginButton"
                  onClick={() => {
                    navigate(NavConstants.login);
                    handleClose();
                  }}
                >
                  Login
                </Button>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.user);

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(logout());
      navigate("/");
    }
  };

  return (
    <Headroom>
      <Container
        fluid
        style={{ background: "#fff", zIndex: 999, position: "relative" }}
        id="navbar-container"
      >
        <Row id="header1" className="pt-3">
          <Col md={3} lg={3} sm={5} xs={5} className="px-4">
            <Link
              to="/"
              exact
              activeClassName="active"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  right: 0,
                  behavior: "smooth",
                })
              }
            >
              <img src={IMAGES.logo.logo} alt="Logo" id="logo" />
            </Link>
          </Col>
          <Col md={8} lg={8} sm={12} xs={12}>
            <div id="header3">
              <nav className="navbar">
                <ul className="nav-list">
                  <li className="nav-item">
                    <NavLink
                      to="/"
                      exact
                      activeClassName="active"
                      onClick={() =>
                        window.scrollTo({
                          top: 0,
                          right: 0,
                          behavior: "smooth",
                        })
                      }
                    >
                      HOME
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="/explore" activeClassName="active">
                      EXPLORE
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/partner"
                      activeClassName="active"
                      onClick={() =>
                        window.scrollTo({
                          top: 0,
                          right: 0,
                          behavior: "smooth",
                        })
                      }
                    >
                      PARTNER
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/whybricxy"
                      activeClassName="active"
                      onClick={() =>
                        window.scrollTo({
                          top: 0,
                          right: 0,
                          behavior: "smooth",
                        })
                      }
                    >
                      WHY BRICXY
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </Col>
          <Col md={1} lg={1} sm={12} xs={12}>
            {token ? (
              <div
                onClick={handleLogout}
                id="header2"
                style={{ height: "90%" }}
              >
                <IoMdLogOut size={23} />
              </div>
            ) : (
              <div id="header2" onClick={() => navigate(NavConstants.login)}>
                <Button variant="contained" id="loginButton">
                  Login
                </Button>
              </div>
            )}
          </Col>
        </Row>

        <div id="mobileViewHeader">
          <Link to="/">
            <img src={IMAGES.logo.logo} alt="Logo" id="logo" />
          </Link>
          {["end"].map((placement, idx) => (
            <MobileViewHeaderComp
              key={idx}
              placement={placement}
              name={placement}
            />
          ))}
        </div>
      </Container>
    </Headroom>
  );
}
export default React.memo(Header);
