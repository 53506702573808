import React from "react";
import "./MyAccount.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MyProfile from "./components/MyProfile/MyProfile";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import Wishlist from "../../../components/Wishlist/Wishlist";
import Faq from "../../Faq/Faq";
import { setNavbarSelectedTab } from "../../../Redux/Slice/userSlice";
import { FaArrowLeftLong } from "react-icons/fa6";
import CreateButton from "../../../assets/Construction/createButton.svg";
import { useNavigate } from "react-router-dom";
import { NavConstants } from "../../../navigations/NavConstants";

export default function MyAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navbarSelectedTab, myAccountSelectedTab } = useSelector(
    (state) => state.user
  );

  const TabSelectedFrom2ndNav = navbarSelectedTab?.tabSelectedFrom2ndNav;

  const handleActiveTabProfile = (value) => {
    dispatch(
      setNavbarSelectedTab({
        exploreORmyproject: "myproject",
        tabSelectedFrom2ndNav: value,
      })
    );
  };

  return (
    <>
      {/* my account / support */}
      <Container fluid className="mt-5 mb-4" style={{ transition: "all 0.6s" }}>
        <Row id="myAccountMainContainer">
          <Col
            xs={{ span: 5, order: "first" }}
            // md={{ span: 5, order: "first" }}
            lg={{ span: 4, order: "first" }}
            className="mt-2 ps-3"
            id="myAccount1"
          >
            <FaArrowLeftLong
              id="myAccount1Icon"
              onClick={() => {
                navigate(NavConstants.myproject);
                dispatch(
                  setNavbarSelectedTab({
                    exploreORmyproject: "myproject",
                    tabSelectedFrom2ndNav: "ongoing",
                  })
                );
              }}
            />{" "}
            <span>
              {myAccountSelectedTab === "myaccount"
                ? "My Profile"
                : myAccountSelectedTab === "mywishlist"
                ? "My Wishlist"
                : // : myAccountSelectedTab === "helpcenter"
                // ? "Help Center"
                myAccountSelectedTab === "faqs"
                ? "FAQs"
                : myAccountSelectedTab === "privacypolicy"
                ? "Privacy Policy"
                : myAccountSelectedTab === "terms"
                ? "Terms & Conditions"
                : null}
            </span>
          </Col>
          <Col
            xs={{ span: 10, order: "last" }}
            // md={{ span: 10, order: "last" }}
            lg={{ span: 4, order: "second" }}
            className="mt-2"
            id="myAccount2"
          >
            <div
              id="myAccount2Text"
              onClick={() => handleActiveTabProfile("myaccount")}
            >
              My Account{" "}
              <div
                id={
                  TabSelectedFrom2ndNav === "myaccount"
                    ? "myAccount2Active"
                    : "myAccount2Inactive"
                }
              />
            </div>
            {/* <div
              id="myAccount2Text"
              onClick={() => handleActiveTabProfile("support")}
            >
              Customer Support{" "}
              <div
                id={
                  TabSelectedFrom2ndNav === "support"
                    ? "myAccount2Active"
                    : "myAccount2Inactive"
                }
              />
            </div> */}
          </Col>
          <Col
            xs={{ span: 7, order: "second" }}
            lg={{ span: 4, order: "last" }}
            id="myAccount3"
            className="pe-3"
          >
            {/* <Button id="myAccount3Button">
              <img src={CreateButton} id="myAccount3ButtonCreate" />
              Get Free Estimation
            </Button> */}
          </Col>
        </Row>
        {/* call components according to value set in redux , i.e., myAccountSelectedTab */}
        <Container fluid className="pt-4">
          <Row>
            <Col>
              {navbarSelectedTab?.tabSelectedFrom2ndNav === "myaccount" ? (
                myAccountSelectedTab === "myaccount" ? (
                  <MyProfile />
                ) : myAccountSelectedTab === "mywishlist" ? (
                  <Wishlist />
                ) : // : myAccountSelectedTab === "helpcenter"
                // ? "Help Center"
                myAccountSelectedTab === "faqs" ? (
                  <Faq />
                ) : myAccountSelectedTab === "privacypolicy" ? (
                  "Privacy Policy"
                ) : myAccountSelectedTab === "terms" ? (
                  <TermsConditions />
                ) : null
              ) : null}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
