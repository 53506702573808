import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { NavConstants } from "./NavConstants";
import Wishlist from "../components/Wishlist/Wishlist";
import Paymenttype from "../components/Paymenttype/Paymenttype";
import HeaderAfterLogin from "../components/HeaderAfterLogin/HeaderAfterLogin";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import Explore from "../Pages/Explore/Explore";
import Myproject from "../Pages/Myproject/Myproject";
import MyAccount from "../Pages/Myproject/MyAccount/MyAccount";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetWishListAsync } from "../Redux/Slice/wishListSlice";
import Calenderslotscheduled from "../components/Calenderslotscheduled/Calenderslotscheduled";

function RootRouterAfterLogin() {
  const { token, userInfo } = useSelector((st) => st?.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetWishListAsync({
        token: token,
        userId: userInfo?._id,
      })
    );
  }, []);
  return (
    <>
      <Router>
        <HeaderAfterLogin />
        <Routes>
          {/* explore pages */}
          <Route path={NavConstants.home} element={<Explore />} />
          <Route path={NavConstants.explore} element={<Explore />} />

          {/* my project pages */}
          <Route path={NavConstants.myproject} element={<Myproject />} />
          <Route path={NavConstants.myaccount} element={<MyAccount />} />

          {/* other pages */}
          <Route path={NavConstants.wishlist} element={<MyAccount />} />
          <Route path={NavConstants.checkout} element={<Paymenttype />} />
          <Route path={NavConstants.slotscheduled} element={<Calenderslotscheduled />} />

          {/* page not found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default RootRouterAfterLogin;
