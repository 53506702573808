import React, { useEffect, useState } from "react";
import "./DesignDetailsModal.css";
import { Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { RxCross1 } from "react-icons/rx";
import Brixcycalendermodal from "../Brixcycalendermodal/Brixcycalendermodal";
import { useSelector } from "react-redux";
import { UserController } from "../../Redux/controllers/UserController";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton CSS for better styling

export default function DesignDetailsModal(props) {
  const [ImagesDesign, setImagesDesign] = useState([]);
  const [loading, setLoading] = useState(true); // Set loading state
  const { roomDetails } = props || {};

  const token = useSelector((state) => state?.user?.token);

  const GetImage = async () => {
    try {
      let response = await UserController.moreDesignComponent({
        roomId: roomDetails?._id,
        token: token,
      });
      setImagesDesign(response?.data?.data?.ourRoomStyle);
    } catch (error) {
      console.log("Error while getting images data", error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    GetImage();
  }, []);

  const carosel = roomDetails?.images || [];
  const [openmodal, setOpenmodal] = useState(false);

  return (
    <Modal
      {...props}
      size="xl"
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body id="modal-body">
        <div id="close-button" onClick={() => props.onHide()}>
          <RxCross1 size={24} />
        </div>
        <Container fluid>
          <Row>
            <Col id="carosel-col" md={6}>
              <Carousel fade id="ch_2" interval={5000}>
                {carosel?.map((item, i) => (
                  <Carousel.Item key={i}>
                    <div id="carousel-image-container">
                      <img
                        src={item}
                        alt="Slide"
                        id="carousel-image"
                        loading="lazy"
                        onError={(e) => (e.target.src = "fallback-image.jpg")} // Provide fallback image on error
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
            <Col id="design-details-col">
              <div id="design-name">{roomDetails?.name || "N/A"}</div>
              <div id="rating-container">
                <Rating
                  initialValue={roomDetails?.rating || 0}
                  size={22}
                  label
                  transition
                  fillColor="#788396"
                  emptyColor="#C0CDE4"
                  readonly
                />
                <div id="rating-numbers">
                  ({roomDetails?.totalNoOfRatings || 0} Ratings)
                </div>
              </div>
              <div id="design-description-text">{roomDetails?.description}</div>
              <div id="button-div">
                <ButtonComponent
                  title="Book a free consultation"
                  borderRadius={40}
                  height={55}
                  onClick={() => setOpenmodal(true)}
                />
              </div>
              <div id="line-div" />

              {ImagesDesign?.length !== 0 && (
                <div id="more-design-label">You may also like</div>
              )}

              <Row>
                {loading
                  ? new Array(6).fill(null).map((_, index) => (
                      <Col md={6} lg={4} xs={12} key={index}>
                        <Skeleton
                          style={{
                            width: "100%",
                            height: 170,
                            borderRadius: "1.4rem",
                          }}
                        />
                      </Col>
                    ))
                  : ImagesDesign?.map((item, index) => (
                      <Col md={6} lg={4} xs={12} key={index}>
                        <img
                          src={item?.images[0]}
                          alt="design image"
                          id="more-design-image"
                          onError={(e) => (e.target.src = "fallback-image.jpg")} // Provide fallback image on error
                        />
                      </Col>
                    ))}
              </Row>
            </Col>
            <Brixcycalendermodal
              show={openmodal}
              onHide={() => setOpenmodal(false)}
              roomStyleId={roomDetails?._id}
            />
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
