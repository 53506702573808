import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Faq.css";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";

function Faq() {
  const { token } = useSelector((state) => state?.user);
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllfaq();
  }, []);

  const getAllfaq = async () => {
    await HttpClient.apiCaller(`admin/faq`, "get", null, token).then((res) => {
      setFaq(res?.data?.data?.faqs || []);
      setLoading(false);
      console.log("Faq data is......", res?.data?.data?.faqs);
    });
  };

  return (
    <>
      <Container>
        <Row id="faq2">
          <Col md={12} lg={12} sm={12} xs={12}>
            <div id="faq1">FAQ Section</div>
          </Col>
        </Row>
        <hr />
        <Row id="faq3">
          <Col md={12} lg={12} sm={12} xs={12}>
            <Accordion defaultActiveKey="0">
              {loading ? (
                Array(5)
                  .fill()
                  .map((_, index) => (
                    <div key={index}>
                      <Skeleton height={30} width="100%" />
                      <Skeleton count={3} />
                      <hr />
                    </div>
                  ))
              ) : (
                faq.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>
                      <div id="faq4">{`${index + 1} . ${item?.question}`}</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div id="faq5">{item?.answer}</div>
                    </Accordion.Body>
                    <hr />
                  </Accordion.Item>
                ))
              )}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(Faq);
