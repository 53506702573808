import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNotFoundImage from "../../assets/pageNotFound1.png";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

export default function PageNotFound() {
  return (
    <Container>
      <Row className="mt-4 mb-4">
        <Col
          style={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={PageNotFoundImage}
            alt="Image"
            style={{ width: "400px", height: "auto" }}
          />
          <h3
            className="mt-5 mb-4"
            style={{ color: "#201A4B", fontFamily: "DM Sans, sans-serif" }}
          >
            Page Not Found
          </h3>
          <ButtonComponent title="Back to Home" width={200} />
        </Col>
      </Row>
    </Container>
  );
}
