import React, { useEffect, useState } from "react";
import { UserController } from "../../../../Redux/controllers/UserController";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../Redux/Slice/userSlice";
import Utility from "../../../../Utility/Utility";

export default function usePayments() {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [allProjectPayments, setAllProjectPayments] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const fetchMyProjectByStatus = async () => {
    try {
      setIsloading(true);
      const response = await UserController.getProjectByStatus({
        status: "ALL",
        token,
      });
      setAllProjectPayments(response?.data?.data?.projects || []);
      setIsloading(false);
    } catch (error) {
      if (
        error.response?.data?.message === "Invalid token. Please log in again!"
      ) {
        dispatch(logout());
      } else {
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  useEffect(() => {
    fetchMyProjectByStatus();
  }, []);

  return { allProjectPayments, isLoading, fetchMyProjectByStatus };
}
