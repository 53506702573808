import React from "react";
import "./Calenderslotscheduled.css";
import { Col, Container, Row } from "react-bootstrap";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { SlCalender } from "react-icons/sl";
import { GiWorld } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
import { IoOpenOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { RedirectToNewTab } from "../RedirectToNewTab/RedirectToNewTab";

function Calenderslotscheduled() {
  const { userInfo } = useSelector((state) => state?.user);
  const location = useLocation();

  const { date, startTime, endTime, weekDay, link } = location.state || {};

  const formattedDate = moment(date).format("DD MMMM YYYY");

  return (
    <Container>
      <Row>
        <Col md={3} lg={3} sm={12} xs={12}></Col>
        <Col md={6} lg={6} sm={12} xs={12}>
          <div id="calendly42">
            <div className="first-box">
              <div className="first-row">
                <span className="tick-span">
                  <FaCheck
                    style={{ color: "white", width: "15px", height: "15px" }}
                  />
                </span>
                <p id="scheduled-p">You are scheduled</p>
              </div>
              <p>A calendar invitation has been sent to your email address.</p>
              <div id="open-button">
                <div onClick={() => RedirectToNewTab(link)}>
                  Open Invitation
                </div>
                <div>
                  <IoOpenOutline
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "-0.3rem",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="second-box">
              <div className="second-box-div">
                <h5>Bricxy Consultation</h5>
                <Row id="calendly7">
                  <Col md={1} lg={1} sm={1} xs={1}>
                    <div>
                      <FaUser />
                    </div>
                  </Col>
                  <Col md={11} lg={11} sm={10} xs={10}>
                    <div id="calendly41">
                      {userInfo?.firstName || "N/A"}{" "}
                      {userInfo?.lastName || "N/A"}
                    </div>
                  </Col>
                </Row>
                <Row id="calendly7">
                  <Col md={1} lg={1} sm={1} xs={1}>
                    <div>
                      <SlCalender />
                    </div>
                  </Col>
                  <Col md={11} lg={11} sm={10} xs={10}>
                    <div id="calendly41">
                      {weekDay}, {formattedDate} {startTime} - {endTime}
                    </div>
                  </Col>
                </Row>
                <Row id="calendly7">
                  <Col md={1} lg={1} sm={1} xs={1}>
                    <div>
                      <GiWorld />
                    </div>
                  </Col>
                  <Col md={11} lg={11} sm={10} xs={10}>
                    <div id="calendly41">India Standard Time</div>
                  </Col>
                </Row>
                <Row id="calendly7">
                  <Col md={1} lg={1} sm={1} xs={1}>
                    <div>
                      <HiOutlineVideoCamera />
                    </div>
                  </Col>
                  <Col md={11} lg={11} sm={10} xs={10}>
                    <div id="calendly41">
                      Web conferencing details to follow.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} sm={12} xs={12}></Col>
      </Row>
    </Container>
  );
}

export default Calenderslotscheduled;
