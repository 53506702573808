import { Button } from "@mui/material";
import React from "react";
import { Spinner } from "react-bootstrap";

export default function ButtonComponent({
  onClick,
  title,
  titleCss,
  loading,
  loadingTitle,
  loadingSize,
  loadingColor,
  width,
  height,
  backgroundColor,
  borderRadius,
  border,
  LeftIcon,
  leftIconSize,
  disabled = false,
}) {
  return (
    <>
      {loading ? (
        <Button
          disabled
          style={{
            width: width || "100%",
            height: height || 45,
            backgroundColor: backgroundColor || "#1B53E4",
            borderRadius: borderRadius || 14,
            border: border || "1px solid #1B53E4",
            color: titleCss?.color || "#fff",
            fontSize: titleCss?.fontSize || "1rem",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: titleCss?.fontWeight || "500",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loadingTitle}{" "}
          <Spinner
            size={loadingSize || "sm"}
            animation="border"
            color={loadingColor}
            className="ms-1"
          />
        </Button>
      ) : (
        <Button
          style={{
            width: width || "100%",
            height: height || 45,
            backgroundColor: backgroundColor || "#1B53E4",
            borderRadius: borderRadius || 14,
            border: border || "1px solid #1B53E4",
            color: titleCss?.color || "#fff",
            fontSize: titleCss?.fontSize || "1rem",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: titleCss?.fontWeight || "500",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {LeftIcon && (
            <LeftIcon
              size={leftIconSize}
              color={titleCss?.color}
              className="me-1"
            />
          )}{" "}
          {title}
        </Button>
      )}
    </>
  );
}
