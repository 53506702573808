export const NavConstants = {
  home: "/",
  signup: "/signup",
  login: "/login",
  otp: "/otp",
  partner: "/partner",
  whybricxy: "/whybricxy",
  houserequirement: "/houserequirement",
  drawing: "/drawing",
  registrationSuccessful: "/registration",

  // footer links
  ourTeam: "/ourTeam",
  aboutus: "/aboutUs",
  contactus: "/contactUs",
  cookiesPolicy: "/cookiesPolicy",
  termsOfUse: "/termsOfUse",
  privacyPolicy: "/privacyPolicy",
  faq: "/faq",
  preset: "/preset",
  residentialsignup: "/property-type/residential",
  commercialsignup: "/property-type/commercial",
  chooseType: "/property-type",
  slotscheduled:"/slotscheduled",

  // after login
  // explore pages
  explore: "/explore",
  construction: "/construction",
  material: "/material",
  materialdetails: "/materialdetails",

  // my projects
  myproject: "/myproject",
  projectdetails: "/projectdetails",
  payments: "/payments",
  discussion: "/discussions",
  myaccount: "/myaccount",

  // other pages
  wishlist: "/wishlist",
  checkout: "/checkout",
  customerdata: "/customerdata",
};
