import React, { useEffect, useState } from "react";
import "./PaymentCard.css";
import { Col, Row } from "react-bootstrap";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import PaymentModel from "../PaymentModel/PaymentModel";
import { FaHistory } from "react-icons/fa";
import Paymenthistorymodal from "../Paymenthistorymodal/Paymenthistorymodal";
import { formatCurrency } from "../../constants/formatCurrency";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";

// PaymentCard component
const PaymentCard = ({
  projectDetails,
  fetchProjectApi = () => {},
  headingTitle = "Payments",
}) => {
  console.log("paymetn", projectDetails);
  const paymentDetails = projectDetails?.paymentDetails || null; // Add default empty object
  const projectStatus = projectDetails?.latestProjectStatus || "";

  const { token } = useSelector((state) => state.user);
  const projectId = projectDetails?._id;
  console.log("Project id is......", projectId);

  const currentPaymentPhase = paymentDetails?.paymentPhases?.find(
    (item) => item.phase === projectStatus
  );

  const isAdminApproved = currentPaymentPhase?.approvalStatus === "APPROVED";
  const isPaymentPaid = currentPaymentPhase?.isPaid;

  useEffect(() => {
    getAllpaymenthistory();
  }, []);

  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const [getpayment, setGetpayment] = useState([]);
  const [openmodal, setOpenmodal] = useState(false);

  const DueAmount = () => {
    if (isPaymentPaid && isAdminApproved) {
      return 0;
    } else {
      return formatCurrency(currentPaymentPhase?.amount) || 0;
    }
  };

  const getAllpaymenthistory = async () => {
    await HttpClient.apiCaller(
      `v1/project/payment/history/${projectId}`,
      "get",
      null,
      token
    )
      .then((res) => {
        setGetpayment(res?.data?.data || []);
        console.log("Payment history is......", res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching payment history:", error);
      });
  };

  if (!paymentDetails) {
    return null;
  }

  return (
    <div id="project-payment-card-container">
      <Row>
        <Col md={8} lg={8} sm={5} xs={5}>
          <div id="material-label">{headingTitle}</div>
        </Col>
        <Col md={4} lg={4} sm={7} xs={7}>
          <div id="paymentcard1" onClick={() => setOpenmodal(true)}>
            <FaHistory id="paymentcard2" />
            Payment History
          </div>
        </Col>
      </Row>
      <Paymenthistorymodal
        show={openmodal}
        onHide={() => setOpenmodal(false)}
        getpayment={getpayment}
      />
      <Row id="paymentcard3">
        <Col md={2} sm={12}>
          <div id="payment-card-div">
            <div id="payment-card-label">Project value</div>
            <div id="payment-card-text">
              ₹{formatCurrency(paymentDetails?.currentEstimation) || 0}
            </div>
          </div>
        </Col>
        <Col md={2} sm={12}>
          <div id="payment-card-div">
            <div id="payment-card-label">Paid Amount</div>
            <div id="payment-card-text">
              ₹{formatCurrency(paymentDetails?.totalAmountPaid) || 0}
            </div>
          </div>
        </Col>
        <Col md={2} sm={12}>
          <div id="payment-card-div">
            <div id="payment-card-label">Remaining Amount</div>
            <div id="payment-card-text">
              ₹{formatCurrency(paymentDetails?.remainingAmount) || 0}
            </div>
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div id="payment-card-pay-emi">
            <div id="payment-card-div">
              <div id="payment-card-label">Due Amount</div>
              <div id="payment-card-text">₹ {DueAmount()}</div>
            </div>
            {!isPaymentPaid && !isAdminApproved && (
              <ButtonComponent
                backgroundColor="transparent"
                title="Pay now"
                titleCss={{ color: "#1B53E4" }}
                width={"70"}
                onClick={() => {
                  setShowPaymentModel(true);
                }}
              />
            )}

            {isPaymentPaid && !isAdminApproved && (
              <ButtonComponent
                backgroundColor="transparent"
                title="Confirmation Pending"
                titleCss={{ color: "green", fontSize: "13px" }}
                border="1px solid green"
                width={"70"}
                disabled={true}
              />
            )}

            {!isPaymentPaid && isAdminApproved && (
              <ButtonComponent
                backgroundColor="transparent"
                title="Pay now"
                titleCss={{ color: "green" }}
                width={"70"}
                disabled={true}
              />
            )}
            {currentPaymentPhase && (
              <PaymentModel
                show={showPaymentModel}
                onHide={() => setShowPaymentModel(false)}
                projectDetails={projectDetails}
                paymentDetails={currentPaymentPhase}
                fetchProjectApi={() => {
                  fetchProjectApi();
                  getAllpaymenthistory();
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(PaymentCard);
