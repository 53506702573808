import React from "react";
import "./Paymenttype.css";
import { Col, Container, Row } from "react-bootstrap";
import Ordersummery from "../Ordersummery/Ordersummery";
import Paymentdetails from "../Paymentdetails/Paymentdetails";

function Paymenttype() {
  return (
    <>
      <Container fluid>
        <Row id="paymenttype1">
          <Col md={8} lg={8} sm={12} xs={12}>
            <div id="paymenttype2">
              <Paymentdetails />
            </div>
          </Col>
          <Col md={4} lg={4} sm={12} xs={12}>
            <div id="paymenttype3">
              <Ordersummery />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(Paymenttype);
