import { memo } from "react";

const ProjectDescription = ({ projectDetails }) => {
  return (
    <div id="description-container">
      <div id="description-label">{projectDetails?.roomStyle?.name}</div>
      <div id="description-text">{projectDetails?.roomStyle?.description}</div>
    </div>
  );
};

export default memo(ProjectDescription);
