import React from "react";
import "./Wishlist.css";
import { Col, Container, Row } from "react-bootstrap";
import Likeditems from "../Likeditems/Likeditems";
import { useSelector } from "react-redux";
import ProductsLikedItems from "../ProductsLikedItems/ProductsLikedItems";
import Loader from "../Loader/Loader";

function Wishlist() {
  const { roomStyleWishList, materialWishList, isGetWishListLoading } =
    useSelector((st) => st?.wishlist);
  const sumOfWishlist =
    Number(roomStyleWishList?.length) + Number(materialWishList?.length);
  return (
    <Container fluid>
      {isGetWishListLoading && <Loader />}
      <div id="wishlist2">
        <div id="wishlist1" className="mb-3">
          Wishlists ({sumOfWishlist})
        </div>
        {sumOfWishlist === 0 && (
          <div id="wishList">Your wishlist is currently empty.</div>
        )}
        <Row id="wishlist6">
          <Likeditems wishList={roomStyleWishList} />
          <ProductsLikedItems wishList={materialWishList} />
        </Row>
      </div>
    </Container>
  );
}

export default React.memo(Wishlist);
