import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";

const initialState = {
  isGetWishListLoading: false,
  isToggleWishListLoading: false,
  // userWishList: [],
  materialWishList: [],
  roomStyleWishList: [],
};

export const GetWishListAsync = createAsyncThunk(
  "wishlist/getWishlist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.getWishlistedProducts(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const ToggleWishlistAsync = createAsyncThunk(
  "wishlist/toggleWishlist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.toggleWishList(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    clearWishList: (state) => {
      state.isGetWishListLoading = false;
      state.isToggleWishListLoading = false;
      state.userWishList = [];
      state.materialWishList = [];
      state.roomStyleWishList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      //user gets all wishlisted items
      .addCase(GetWishListAsync.pending, (state, action) => {
        state.isGetWishListLoading = true;
      })
      .addCase(GetWishListAsync.fulfilled, (state, action) => {
        console.log("getting wish list redux", action?.payload);

        state.userWishList = action?.payload?.data || [];

        state.roomStyleWishList =
          action?.payload?.data?.roomStyleWishList || [];
        state.materialWishList = action?.payload?.data?.productWishList || [];
        state.isGetWishListLoading = false;
      })
      .addCase(GetWishListAsync.rejected, (state, action) => {
        state.isGetWishListLoading = false;
      })
      //   toggle wish list status
      .addCase(ToggleWishlistAsync.pending, (state, action) => {
        state.isToggleWishListLoading = true;
      })
      .addCase(ToggleWishlistAsync.fulfilled, (state, action) => {
        // state.userWishList = action?.payload?.data?.user?.wishList || [];
        state.isToggleWishListLoading = false;
      })
      .addCase(ToggleWishlistAsync.rejected, (state, action) => {
        state.isToggleWishListLoading = false;
      });
  },
});

export const { clearWishList } = wishlistSlice.actions;
export default wishlistSlice.reducer;
