import React, { useState, useEffect, useCallback } from "react";
import { DayPicker } from "react-day-picker";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FiClock } from "react-icons/fi";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import "react-day-picker/dist/style.css";
import "./Brixcycalendermodal.css";
import { Button } from "@mui/material";
import Chooseslot from "../Chooseslot/Chooseslot";
import { useSelector } from "react-redux";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import moment from "moment/moment";
import { FaEarthAmericas } from "react-icons/fa6";
import { IoMdArrowRoundBack } from "react-icons/io";
import waiting from "../../assets/Brixcycalender/waiting.gif";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Brixcycalendermodal({
  show,
  onHide,
  roomStyleId,
  isReconsulting = false,
  projectId = "",
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [showSlots, setShowSlots] = useState(false);
  const [openslotmodal, setOpenslotmodal] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [isHoliday, setIsHoliday] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [rootSlotId, setRootSlotId] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { token, userInfo } = useSelector((state) => state?.user);

  const updateTime = useCallback(() => {
    const indiaTime = new Intl.DateTimeFormat("en-US", {
      timeZone: "Asia/Kolkata",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    }).format(new Date());

    setCurrentTime(indiaTime);
  }, []);

  useEffect(() => {
    updateTime();

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [updateTime]);

  const handleDateChange = (date) => {
    const MyDate = new Date(
      new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000
    ).toISOString();

    setStartDate(date);
    setSelectedDate(MyDate);
    setShowSlots(true);
    getTimeslot(MyDate);
  };

  const getTimeslot = (date) => {
    setIsLoading(true);
    const formattedDate = moment(new Date(date)).startOf("day").toDate();

    HttpClient.apiCaller(
      `v1/project/consult/booked-slots?date=${formattedDate}`,
      "get",
      null,
      token
    )
      .then((res) => {
        const data = res?.data?.data;
        setRootSlotId(data?.slotId);
        setAvailableSlots(data?.timeSlots || []);
        setIsHoliday(data?.isHoliday || false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSlotClick = (slot) => {
    if (!slot?.isEnabled || slot?.isBooked || slot?.isHoliday) {
      return;
    }
    setSelectedSlot(slot);
    setOpenslotmodal(true);
  };

  const handleBackClick = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} fullscreen>
      <Modal.Body id="calendly18">
        <Container>
          <div id="calendly1">
            <Row id="calendly11">
              <Col
                md={showSlots ? 4 : 6}
                lg={showSlots ? 4 : 6}
                sm={12}
                xs={12}
                className="calendly-column"
              >
                <div id="calendly12">
                  <Row>
                    <Col md={1} lg={1} sm={2} xs={2}>
                      <IoMdArrowRoundBack
                        onClick={handleBackClick}
                        id="calendly15"
                      />
                    </Col>
                  </Row>
                  <hr></hr>

                  <div id="calendly5">
                    {userInfo?.firstName || "N/A"} {userInfo?.lastName || "N/A"}
                  </div>
                  <div id="calendy6">Bricxy Consultation</div>
                  {/* <Row id="calendly7">
                    <Col md={1} lg={1} sm={2} xs={2}>
                      <div>
                        <FiClock />
                      </div>
                    </Col>
                    <Col md={11} lg={11} sm={10} xs={10}>
                      <div id="calendly8">1 Hr</div>
                    </Col>
                  </Row> */}
                  <Row id="calendly7">
                    <Col md={1} lg={1} sm={2} xs={2}>
                      <div>
                        <HiOutlineVideoCamera />
                      </div>
                    </Col>
                    <Col md={11} lg={11} sm={10} xs={10}>
                      <div id="calendly8">
                        Web conferencing details provided upon confirmation.
                      </div>
                    </Col>
                  </Row>
                  <div id="calendly9">Join for one-on-one discussion</div>
                  <div id="calendly17">
                    <img src={waiting} id="calendly16" />
                  </div>
                </div>
              </Col>
              <Col
                md={showSlots ? 4 : 6}
                lg={showSlots ? 4 : 6}
                sm={12}
                xs={12}
                className="calendly-column"
              >
                <div id="calendly13">Select Date & Time</div>
                <hr></hr>
                <div id="calendly2">
                  <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onDayClick={handleDateChange}
                    disabled={(date) => {
                      const currentDate = new Date();
                      currentDate.setHours(0, 0, 0, 0);
                      return date < currentDate;
                    }}
                  />
                </div>
                <div id="calendly14">Time Zone</div>
                <Row id="calendly7">
                  <Col md={1} lg={1} sm={2} xs={2}>
                    <div>
                      <FaEarthAmericas />
                    </div>
                  </Col>
                  <Col md={11} lg={11} sm={10} xs={10}>
                    <div id="calendly8">
                      Indian Standard Time ({currentTime})
                    </div>
                  </Col>
                </Row>
              </Col>

              {showSlots && selectedDate && (
                <Col md={4} lg={4} sm={12} xs={12} className="slots-container">
                  {isLoading ? (
                    <div>
                      <Skeleton
                        height={40}
                        count={8}
                        style={{ marginBottom: "10px" }}
                      />
                    </div>
                  ) : isHoliday ? (
                    <div id="calendly10">
                      Today is a holiday. No slots available.
                    </div>
                  ) : availableSlots?.length === 0 ? (
                    <div id="calendly10">
                      No slots available for the selected date.
                    </div>
                  ) : (
                    <>
                      <div id="calendy3">
                        Slots for {moment(selectedDate).format("MMMM Do YYYY")}
                      </div>
                      <hr></hr>
                      <Row>
                        {availableSlots.map((slot, index) => (
                          <Col
                            md={6}
                            lg={6}
                            sm={6}
                            xs={12}
                            key={index}
                            className="mb-2"
                          >
                            <Button
                              block
                              id="calendly4"
                              onClick={() => handleSlotClick(slot)}
                              style={{
                                cursor:
                                  !slot?.isEnabled ||
                                  slot?.isBooked ||
                                  slot?.isHoliday
                                    ? "no-drop"
                                    : "pointer",
                                backgroundColor:
                                  !slot?.isEnabled ||
                                  slot?.isBooked ||
                                  slot?.isHoliday
                                    ? "#e0e0e0"
                                    : "",
                                color:
                                  !slot?.isEnabled ||
                                  slot?.isBooked ||
                                  slot?.isHoliday
                                    ? "#666"
                                    : "",
                              }}
                            >
                              {`${slot?.startTime} - ${slot?.endTime}`}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </Col>
              )}
            </Row>
            <Chooseslot
              show={openslotmodal}
              onHide={() => setOpenslotmodal(false)}
              roomStyleId={roomStyleId}
              date={selectedDate}
              slotId={rootSlotId}
              timeSlotId={selectedSlot?.timeSlotId}
              startTime={selectedSlot?.startTime}
              endTime={selectedSlot?.endTime}
              projectId={projectId}
              isReconsulting={isReconsulting || false}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
