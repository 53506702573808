import React, { useState } from "react";
import "./Construction.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import modern from "../../../assets/Construction/Group 118.png";
import European from "../../../assets/Construction/Group 118 (1).png";
import colonial from "../../../assets/Construction/Group 118 (2).png";
import cottage from "../../../assets/Construction/Group 118 (3).png";
import ranch from "../../../assets/Construction/Group 118 (4).png";
import { IoHeart } from "react-icons/io5";

import DesignDetailsModal from "../../../components/DesignDetailsModal/DesignDetailsModal";
import useConstruction from "../hooks/useConstruction";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetWishListAsync,
  ToggleWishlistAsync,
} from "../../../Redux/Slice/wishListSlice";
import Utility from "../../../Utility/Utility";
import { Category } from "@mui/icons-material";
import Loader from "../../../components/Loader/Loader";

export default function Construction() {
  const { token, userInfo } = useSelector((st) => st?.user);
  const { isToggleWishListLoading, roomStyleWishList } = useSelector(
    (st) => st?.wishlist
  );
  const {
    categories,
    isProductsLoading,
    isCategoriesLoading,
    getFirstTwoWords,
    loadingArray,
    SkeltonCategoryLoader,
    setShowDesignDetailsModal,
    showDesignDetailsModal,
    selectedCategory,
    products,
    handleSearch,
    setSelectedCategory,
    noProductsMessage,
    selectedRoomStyle,
    searchInput,
    setSelectedRoomStyle,
  } = useConstruction();
  const isWishlistedFun = (id) => {
    let wishlistIds = roomStyleWishList.map((item) => item?._id);
    return wishlistIds.includes(id);
  };

  console.log("cat---------------page", categories);

  const dispatch = useDispatch();
  const handleToggleWishlist = (item) => {
    dispatch(
      ToggleWishlistAsync({
        token: token,
        data: {
          roomStyleId: item?._id,
          userId: userInfo?._id,
        },
      })
    )
      .then((res) => {
        // Utility.sToast(res?.payload?.message);
        dispatch(
          GetWishListAsync({
            token: token,
            userId: userInfo?._id,
          })
        );
      })
      .catch(() => {})
      .finally(() => {});
  };
  return (
    <>
      {isCategoriesLoading ? (
        <Loader />
      ) : (
        <Container fluid className="pt-3">
          <Row id="construction2">
            <Col md={12} lg={12} sm={12} xs={12}>
              <div id="construction1">
                <div id="construction3">What you are looking for ?</div>
                <Row id="construction5">
                  <Col md={3} lg={3} sm={12} xs={12}></Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <div className="search-container">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          size="lg"
                          id="construction4"
                          value={searchInput}
                          onChange={handleSearch}
                        />
                        <CiSearch className="search-icon" />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={3} lg={3} sm={12} xs={12}></Col>
                </Row>
                <Row id="construction9">
                  <Col xs={12}>
                    <Row id="construction9Col">
                      <Col />
                      {isCategoriesLoading
                        ? loadingArray.map(() => (
                            <Col>
                              <SkeltonCategoryLoader />
                            </Col>
                          ))
                        : categories?.map((item, i) => (
                            <Col id="category-col" key={i}>
                              <div
                                id={
                                  selectedCategory === i
                                    ? "construction12Active"
                                    : "construction12"
                                }
                                onClick={() => setSelectedCategory(i)}
                              >
                                <img
                                  src={item?.categoryImage || "N/A"}
                                  id="category-img"
                                />
                              </div>
                              <div id="construction10">
                                {getFirstTwoWords(item?.categoryName) || "N/A"}
                              </div>
                            </Col>
                          ))}
                      <Col />
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="mt-2" id="construction13">
            {products?.length === 0 && (
              <div id="no-product-found">{noProductsMessage}</div>
            )}

            {isProductsLoading
              ? loadingArray.map((item, i) => (
                  <Col key={i} md={4} lg={4} sm={12} xs={12}>
                    <Skeleton
                      style={{
                        width: "100%",
                        height: 200,
                        borderRadius: "1.2rem",
                        marginBottom: ".5rem",
                      }}
                    />
                  </Col>
                ))
              : products?.map((item, index) => (
                  <Col
                    md={4}
                    lg={4}
                    sm={12}
                    xs={12}
                    key={index}
                    className="text-center"
                    id="image-container"
                  >
                    <div
                      onClick={() => {
                        setSelectedRoomStyle(item);
                        setShowDesignDetailsModal(true);
                      }}
                    >
                      <img
                        src={item?.images[0]}
                        alt={`House ${index + 1}`}
                        className="img-fluid"
                      />
                    </div>
                    {/* <IoHeart
                      className={`${
                        isWishlistedFun(item?._id)
                          ? "wishlisted-heart-icon"
                          : "heart-icon"
                      }`}
                      style={{
                        cursor: isToggleWishListLoading
                          ? "progress"
                          : "pointer",
                      }}
                      onClick={() => handleToggleWishlist(item)}
                    /> */}
                    <div className="image-overlay">{item?.name || "N/A"}</div>
                  </Col>
                ))}
          </Row>
          {showDesignDetailsModal && (
            <DesignDetailsModal
              show={showDesignDetailsModal}
              onHide={() => setShowDesignDetailsModal(false)}
              roomDetails={selectedRoomStyle}
            />
          )}
        </Container>
      )}
    </>
  );
}
