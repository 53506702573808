import React, { useState } from "react";
import "./Paymentdetails.css";
import { Col, Row } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Razorpay from "../../assets/Paymenttype/Razorpay.svg";
import Visa from "../../assets/Paymenttype/Visa.svg";
import cancellation from "../../assets/Paymenttype/cancellation.svg";
import { Button } from "@mui/material";

function Paymentdetails() {
  const [selectedValue, setSelectedValue] = useState("1");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div id="paymentdetails1">
            <div id="paymentdetails2">Select a Payment option</div>
            <div id="paymentdetails3">
              All Transactions are secure and enrypted
            </div>
            <Row id="paymentdetails4">
              <Col md={6} lg={6} sm={12} xs={12}>
                <div id="paymentdetails5">
                  <Row>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quantity"
                          name="controlled-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Razorpay"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <div id="paymentdetails6">
                        <img src={Razorpay} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <div id="paymentdetails5">
                  <Row>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quantity"
                          name="controlled-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Pay in EMI"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <div id="paymentdetails8">EMI CALCULATOR</div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <div id="paymentdetails5">
                  <Row>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quantity"
                          name="controlled-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Credit Card"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <div id="paymentdetails7">
                        <img src={Visa} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <div id="paymentdetails5">
                  <Row>
                    <Col md={7} lg={7} sm={7} xs={7}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quantity"
                          name="controlled-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Pay on Delivery"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                    <Col md={5} lg={5} sm={5} xs={5}>
                      <div id="paymentdetails6">
                        <img src={Razorpay} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div id="paymentdetails10">
                  <Button id="paymentdetails11">Pay ₹36,500</Button>
                </div>
              </Col>
            </Row>
          </div>
          <div id="paymentdetails12">
            <Row>
              <Col md={9} lg={9} sm={12} xs={12}>
                <div id="paymentdetails13">Cancellation policy</div>
                <div id="paymentdetails14">
                  Our cancellation policy allows for a full refund if
                  cancellation is made at least 48 hours prior to the scheduled
                  booking or appointment. Cancellations made within 48 hours may
                  be subject to a partial refund or cancellation fee. Please
                  contact us directly to inquire about specific cancellation
                  terms for your reservation
                </div>
                <div id="paymentdetails15">
                  <Button id="paymentdetails16">Know more</Button>
                </div>
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <div id="paymentdetails17">
                  <img src={cancellation} id="paymentdetails18" />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(Paymentdetails);
