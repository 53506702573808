import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import "./Materialtabs.css";
import MaterialsComp from "./MaterialsComp/MaterialsComp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Row } from "react-bootstrap";

export default function Materialtabs({
  materialsDataArray,
  isProductsLoading,
  classCategoryArray,
  isClassLoading,
  setActiveClass,
}) {
  // Set initial state with the first tab object
  const [activeTab, setActiveTab] = useState({});

  console.log("active Tab", activeTab);

  useEffect(() => {
    setActiveTab(classCategoryArray[0]);
  }, []);

  const handleChange = (event, newValue) => {
    console.log("change", newValue);
    setActiveTab(newValue);
    setActiveClass(newValue);
  };

  const tabProps = (item) => ({
    value: item,
    className: `legal-tab ${
      activeTab?.heading === item.heading ? "active" : ""
    }`,
    label: item.heading,
    sx: { fontWeight: activeTab?.heading === item.heading ? "600" : "normal" },
  });

  const loadingArray = new Array(6).fill(" ");

  const SkeltonLoader = React.memo(() => (
    <Skeleton
      style={{
        width: "100%",
        height: 270,
        borderRadius: "5%",
      }}
    />
  ));

  return (
    <div className="legal-tabs-container">
      <TabContext value={activeTab?.heading}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="legal tabs"
          className="legal-tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {classCategoryArray.map((item) => (
            <Tab key={item._id} {...tabProps(item)} />
          ))}
        </Tabs>

        <div className="legal-tab-content">
          {isProductsLoading ? (
            <Row>
              {loadingArray.map((item, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
                  <SkeltonLoader />
                </Col>
              ))}
            </Row>
          ) : materialsDataArray?.length === 0 ? (
            <div id="materialtabs5">No Materials Found</div>
          ) : (
            <TabPanel value={activeTab?.heading}>
              <MaterialsComp data={materialsDataArray} />
            </TabPanel>
          )}
        </div>
      </TabContext>
    </div>
  );
}
