import React, { useState } from "react";
import "./Ordersummery.css";
import { Col, Row } from "react-bootstrap";
import item1 from "../../assets/Wishlist/Group 82.svg";
import item2 from "../../assets/Wishlist/image 41.svg";
import coupone from "../../assets/Paymenttype/Coupone.svg";
import { GoChevronDown } from "react-icons/go";

function Ordersummery() {
  const [isCouponDropdownVisible, setIsCouponDropdownVisible] = useState(false);

  const toggleCouponDropdown = () => {
    setIsCouponDropdownVisible(!isCouponDropdownVisible);
  };

  const items = [
    {
      id: 1,
      image: item1,
      name: "Burnt Clay Brick",
      price: "₹ 7000 (1000pcs)",
      qty: "2000",
      totalprice: "₹ 14,000",
    },
    {
      id: 2,
      image: item2,
      name: "Acc Cement",
      price: "₹ 350/Bags",
      qty: "2000",
      totalprice: "₹ 14,000",
    },
  ];

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div id="ordersummery1">
            <div id="ordersummery8">My Cart (6)</div>
            {items.map((item) => (
              <Col key={item.id} md={12} lg={12} sm={12} xs={12}>
                <div id="ordersummery2">
                  <Row>
                    <Col md={5} lg={5} sm={5} xs={5}>
                      <div>
                        <img
                          src={item.image}
                          alt={item.name}
                          id="ordersummery3"
                        />
                      </div>
                    </Col>
                    <Col md={7} lg={7} sm={7} xs={7}>
                      <div></div>
                      <div id="ordersummery4">{item.name}</div>
                      <div id="ordersummety5">{item.price}</div>
                      <div id="ordersummety6">{item.qty}</div>
                      <div id="ordersummety7">{item.totalprice}</div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div id="ordersummery15" onClick={toggleCouponDropdown}>
                  <Row>
                    <Col md={2} lg={2} sm={2} xs={2}>
                      <div>
                        <img src={coupone} alt="Coupon" />
                      </div>
                    </Col>
                    <Col md={8} lg={8} sm={8} xs={8}>
                      <div id="ordersummery16">Apply coupon code</div>
                    </Col>
                    <Col md={2} lg={2} sm={2} xs={2}>
                      <div id="ordersummery17">
                        <GoChevronDown id="ordersummery18" />
                      </div>
                    </Col>
                  </Row>
                </div>
                {isCouponDropdownVisible && (
                  <div id="couponDropdown">
                    <ul>
                      <li>Bricxy1 - 10% off</li>
                      <li>Bricxy2 - 20% off</li>
                      <li>Bricxy3 - 30% off</li>
                    </ul>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div id="ordersummery9">
                  <div id="ordersummery11">Order summary</div>
                  <div>
                    <Row id="ordersummery12">
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery14">Subtotal </div>
                      </Col>
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery13">₹ 28,000</div>
                      </Col>
                    </Row>
                    <Row id="ordersummery12">
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery14">Shipping charge </div>
                      </Col>
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery13">₹ 2,000</div>
                      </Col>
                    </Row>
                    <Row id="ordersummery12">
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery14">Tax </div>
                      </Col>
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery13">₹ 4,000</div>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery14">Total value </div>
                      </Col>
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="ordersummery13">₹ 34,000</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(Ordersummery);
