import React from "react";
import "./Likeditems.css";
import { Col, Row } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button } from "@mui/material";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  GetWishListAsync,
  ToggleWishlistAsync,
} from "../../Redux/Slice/wishListSlice";
import Utility from "../../Utility/Utility";

function Likeditems({ wishList = [] }) {
  const { token, userInfo } = useSelector((st) => st?.user);
  const dispatch = useDispatch();
  const handleDeleteWishList = (item) => {
    dispatch(
      ToggleWishlistAsync({
        token: token,
        data: {
          roomStyleId: item?._id,
          userId: userInfo?._id,
        },
      })
    )
      .then((res) => {
        Utility.sToast(res?.payload?.message);
        dispatch(
          GetWishListAsync({
            token: token,
          })
        );
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <>
      {wishList?.map((item) => (
        <Col key={item?._id} md={4} lg={4} sm={12} xs={12}>
          <div id="wishlist5">
            <Row>
              <Col md={5} lg={5} sm={5} xs={5}>
                <div>
                  <img
                    src={item?.images[0] || "#"}
                    alt={item?.name}
                    id="wishlist9"
                  />
                </div>
              </Col>
              <Col md={7} lg={7} sm={7} xs={7}>
                <div>
                  <Row>
                    <Col md={9} lg={9} sm={9} xs={9}></Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div
                        id="wishlist7"
                        onClick={() => handleDeleteWishList(item)}
                      >
                        <RiDeleteBin6Line id="wishlist8" />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div id="wishlist10">{item?.name}</div>
                <div id="wishlist11">{item?.price}</div>
                {/* <div id="wishlist12">
                          <Button id="wishlist13">
                            Move to Cart{" "}
                            <HiOutlineShoppingBag id="wishlist14" />
                          </Button>
                        </div> */}
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </>
  );
}

export default React.memo(Likeditems);
