import React, { useEffect, useState } from "react";
import "./MaterialdetailsModal.css";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import ReactImageMagnify from "react-image-magnify";
import { useSelector } from "react-redux";
import Similarproduct from "../Similarproduct/Similarproduct";
import Comments from "../Comments/Comments";
import Addcomments from "../Addcomments/Addcomments";
import { FaRegHeart } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { UserController } from "../../Redux/controllers/UserController";
import Utility from "../../Utility/Utility";
import Loader from "../Loader/Loader"; // Assuming you have a Loader component
import { HttpClient } from "../../Redux/controllers/HttpClient";

function MaterialdetailsModal({ show, handleClose, materialDetails }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [allreview, setAllreview] = useState([]);

  const { token } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const productId = materialDetails?._id;

  useEffect(() => {
    if (productId) {
      fetchSimilarProducts();
      getAllreveiws();
    }
  }, [productId, token]);

  const getAllreveiws = async () => {
    try {
      await HttpClient.apiCaller(
        `v1/product-review?productId=${productId}`,
        "get",
        null,
        token
      ).then((res) => {
        setAllreview(res?.data?.data);
        console.log("All Reviews are......", res?.data?.data);
      });
    } catch (err) {
      Utility.eToast(
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          err.message ||
          "Couldn't fetch similar products"
      );
    }
  };

  const fetchSimilarProducts = async () => {
    try {
      const response = await UserController.getSimilarProducts({
        productId,
        token,
      });
      setSimilarProducts(response?.data?.data?.similarProducts || []);
      setIsLoading(false);
    } catch (err) {
      Utility.eToast(
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          err.message ||
          "Couldn't fetch similar products"
      );
    }
  };

  if (isLoading) {
    return <Loader />; // Show loader while fetching data
  }

  return (
    <Modal size="xl" fullscreen show={show} onHide={handleClose}>
      <Modal.Body style={{ overflowY: "scroll" }}>
        <Row className="pt-5 pb-5">
          <Col />
          <Col xs={12} lg={10}>
            {/* Modal Header with Breadcrumb */}
            <div id="materialDetailsBreadcrumbMain">
              <div id="materialDetailsBreadcrumb">
                <span
                  id="materialDetailsBreadcrumbSpan"
                  onClick={handleClose}
                >{`Home > `}</span>
                <span
                  id="materialDetailsBreadcrumbSpan"
                  onClick={handleClose}
                >{`Explore > `}</span>
                <span
                  id="materialDetailsBreadcrumbSpan"
                  onClick={handleClose}
                >{`Materials > `}</span>
                {`${materialDetails?.name}`}
              </div>
              <GrClose
                size={25}
                style={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>

            {/* Main Content */}
            <Row id="materialdetails1">
              {/* Left Side - Image Gallery */}
              <Col md={6} lg={6} sm={12} xs={12} className="mt-3">
                <Row>
                  {/* Thumbnail Images */}
                  <Col md={2} lg={2} sm={2} xs={2}>
                    {materialDetails?.productImages?.map((image, index) => (
                      <div key={index} style={{ marginBottom: "10px" }}>
                        <img
                          src={image}
                          alt={`Tile ${index + 1}`}
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => setCurrentImageIndex(index)}
                        />
                      </div>
                    ))}
                  </Col>
                  {/* Main Image with Magnify */}
                  <Col md={10} lg={10} sm={10} xs={10}>
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Image",
                          isFluidWidth: true,
                          src: materialDetails?.productImages?.[
                            currentImageIndex
                          ],
                        },
                        largeImage: {
                          src: materialDetails?.productImages?.[
                            currentImageIndex
                          ],
                          width: 1200,
                          height: 1800,
                        },
                        enlargedImageContainerDimensions: {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              {/* Right Side - Product Details */}
              <Col md={6} lg={6} sm={12} xs={12} className="mt-3">
                <div>
                  <div id="materialdetails2">
                    {materialDetails?.name || "N/A"}
                  </div>
                  <div id="materialdetails4">
                    <Rating
                      initialValue={materialDetails?.ratings}
                      size={25}
                      transition
                      fillColor="#788396"
                      emptyColor="#C0CDE4"
                      readonly
                      allowFraction={true}
                    />
                  </div>
                  <div id="materialdetails6">
                    {materialDetails?.description || "N/A"}
                  </div>
                  <hr id="materialdetails7"></hr>
                  <Row>
                    <Col md={4} lg={4} sm={6} xs={6}>
                      <div id="materialdetails11">Material</div>
                      <div id="materialdetails8">
                        {materialDetails?.material || "N/A"}
                      </div>
                    </Col>
                    <Col md={4} lg={4} sm={6} xs={6}>
                      <div id="materialdetails11">Size</div>
                      <div id="materialdetails8">
                        {materialDetails?.size || "N/A"}
                      </div>
                    </Col>
                    <Col md={4} lg={4} sm={6} xs={6}>
                      <div id="materialdetails11">Design</div>
                      <div id="materialdetails8">
                        {materialDetails?.design || "N/A"}
                      </div>
                    </Col>
                  </Row>
                  <hr id="materialdetails9"></hr>
                  <div id="materialdetails11">Color options</div>
                  <Row id="materialdetails16">
                    {materialDetails?.colorImages?.map((image, index) => (
                      <Col
                        key={index}
                        md={3}
                        lg={3}
                        sm={6}
                        xs={6}
                        className="mt-3"
                      >
                        <img
                          src={image}
                          alt={`Color option ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "90px",
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Similar Products */}
            {similarProducts?.length > 0 && (
              <Row id="materialdetails17">
                <Col md={12} lg={12} sm={12} xs={12}>
                  <Similarproduct similarProducts={similarProducts} />
                </Col>
              </Row>
            )}

            {/* Comments Section */}
            {allreview?.length > 0 && (
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <Comments allreview={allreview} />
                </Col>
              </Row>
            )}

            {/* Add Comment */}
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <Addcomments
                  productId={materialDetails?._id}
                  fetchReviewApi={() => getAllreveiws()}
                />
              </Col>
            </Row>
          </Col>
          <Col />
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(MaterialdetailsModal);
