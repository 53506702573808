import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavConstants } from "./NavConstants";
import Header from "../components/Header/Header";
import ScrollToTop from "../components/ScrollToTop.js/ScrollToTop";

// Lazy-loaded components
const Footer = lazy(() => import("../components/Footer/Footer"));
const Home = lazy(() => import("../Pages/Home/Home"));
const Explore = lazy(() => import("../Pages/Explore/Explore"));
const Partner = lazy(() => import("../Pages/Partner/Partner"));
const Whybrixcy = lazy(() => import("../Pages/Whybrixcy/Whybrixcy"));
const Aboutus = lazy(() => import("../Pages/Aboutus/Aboutus"));
const Contactus = lazy(() => import("../Pages/Contactus/Contactus"));
const CookiesPolicy = lazy(() =>
  import("../Pages/CookiesPolicy/CookiesPolicy")
);
const TermsOfUse = lazy(() => import("../Pages/TermsOfUse/TermsOfUse"));
const PrivacyPolicy = lazy(() =>
  import("../Pages/PrivacyPolicy/PrivacyPolicy")
);
const Faq = lazy(() => import("../Pages/Faq/Faq"));
const Presets = lazy(() => import("../Pages/Presets/Presets"));
const Signup = lazy(() => import("../Pages/Signup/Signup"));
const RegistrationSuccessful = lazy(() =>
  import("../Pages/RegistrationSuccessful/RegistrationSuccessful")
);
const LoginWithOTP = lazy(() => import("../Pages/LoginWithOTP/LoginWithOTP"));
const Customerdata = lazy(() =>
  import("../components/Customerdata/Customerdata")
);
const OurTeam = lazy(() => import("../Pages/OurTeam/OurTeam"));
const ChoosePropertyType = lazy(() =>
  import("../Pages/ChoosePropertyType/ChoosePropertyType")
);
const CommercialSignup = lazy(() =>
  import("../Pages/CommercialSignup/CommercialSignup")
);

export default function RootRouter() {
  const hideFooterRoutes = [
    NavConstants.chooseType,
    NavConstants.registrationSuccessful,
    NavConstants.residentialsignup,
    NavConstants.commercialsignup,
  ];

  const shouldHideFooter = hideFooterRoutes.includes(window.location.pathname);

  return (
    <Router>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path={NavConstants.residentialsignup} element={<Signup />} />
          <Route path={NavConstants.login} element={<LoginWithOTP />} />
          <Route
            path={NavConstants.commercialsignup}
            element={<CommercialSignup />}
          />
          <Route path={NavConstants.home} element={<Home />} />
          <Route path={NavConstants.explore} element={<Explore />} />
          <Route path={NavConstants.partner} element={<Partner />} />
          <Route path={NavConstants.whybricxy} element={<Whybrixcy />} />
          <Route path={NavConstants.aboutus} element={<Aboutus />} />
          <Route
            path={NavConstants.chooseType}
            element={<ChoosePropertyType />}
          />
          <Route path={NavConstants.ourTeam} element={<OurTeam />} />
          <Route path={NavConstants.contactus} element={<Contactus />} />
          <Route
            path={NavConstants.cookiesPolicy}
            element={<CookiesPolicy />}
          />
          <Route path={NavConstants.termsOfUse} element={<TermsOfUse />} />
          <Route
            path={NavConstants.privacyPolicy}
            element={<PrivacyPolicy />}
          />
          <Route path={NavConstants.faq} element={<Faq />} />
          <Route path={NavConstants.preset} element={<Presets />} />
          <Route
            path={NavConstants.registrationSuccessful}
            element={<RegistrationSuccessful />}
          />
          <Route path={NavConstants.customerdata} element={<Customerdata />} />
        </Routes>
      </ScrollToTop>
      {!shouldHideFooter && <Footer />}
    </Router>
  );
}
