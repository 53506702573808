import React, { useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { UserController } from "../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Utility from "../../Utility/Utility";

function PaymentModel(props) {
  const { projectDetails, paymentDetails, fetchProjectApi } = props;
  const { token } = useSelector((state) => state.user);
  const projectId = projectDetails?._id;

  const [multipleImgUrl, setMultipleImgUrl] = useState([]);
  const [isImgUploading, setIsImgUploading] = useState(false);

  function Validate() {
    if (multipleImgUrl?.length === 0) {
      Utility.eToast("Please Select a document to upload");
      return false;
    }
    return true;
  }

  const handleUploadDoc = async (event) => {
    try {
      setIsImgUploading(true);
      const selectedFiles = event.target.files;
      const fileArray = Array.from(selectedFiles);

      const doc = new FormData();

      fileArray.forEach((file) => {
        doc.append("images", file);
      });

      const onProgress = (progress) => {
        console.log(`Upload progress: ${progress}%`);
      };

      const response = await UserController.multiImageUpload(doc, onProgress);
      Utility.sToast("Images uploaded successfully");
      setMultipleImgUrl(response.data.data);
      console.log("response of image", response.data.data);
    } catch (error) {
      console.error("File upload failed", error);
      Utility.eToast(
        error.response.data.message ||
          error.message ||
          "Oops! Something went wrong"
      );
    } finally {
      setIsImgUploading(false);
    }
  };

  const handleSubmitPaymentDetails = async () => {
    if (Validate()) {
      try {
        const data = {
          documents: multipleImgUrl,
          phaseId: paymentDetails?._id,
        };
        const response = UserController.updatePaymentDetails({
          data,
          token,
          id: projectId,
        });
        console.log("project payment res", response);
        props.onHide();
        fetchProjectApi();
        Utility.sToast("Payment Details Send Successfully");
      } catch (error) {
        console.error("Error updating project", error);
        Utility.eToast(
          error.response.data.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment Receipt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Upload Payment Receipt</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/*" // To ensure only image files are selected
                  onChange={handleUploadDoc}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col />
            <Col md={3}>
              <ButtonComponent
                backgroundColor="red"
                border="none"
                title="Cancel"
                disabled={isImgUploading}
                onClick={() => props.onHide()}
              />
            </Col>
            <Col md={3}>
              <ButtonComponent
                title="Save"
                loading={isImgUploading}
                loadingTitle={isImgUploading ? "Uploading" : ""}
                onClick={handleSubmitPaymentDetails}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default PaymentModel;
