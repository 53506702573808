import React from "react";
import "./Payments.css";
import { Row } from "react-bootstrap";
import PaymentCard from "../../../components/PaymentCard/PaymentCard";
import usePayments from "./hooks/usePayments";
import Loader from "../../../components/Loader/Loader";

export default function Payments() {
  const { allProjectPayments, isLoading, fetchMyProjectByStatus } =
    usePayments();

  return (
    <Row>
      {isLoading && <Loader />}
      {allProjectPayments?.map((item, index) => (
        <div
          style={{
            paddingLeft: "2rem",
            paddingRight: "1.8rem",
            marginTop: ".4rem",
            marginBottom: ".4rem",
          }}
        >
          <PaymentCard
            headingTitle={item?.roomStyle?.name}
            projectDetails={item}
            fetchProjectApi={() => fetchMyProjectByStatus()}
          />
        </div>
      ))}
    </Row>
  );
}
