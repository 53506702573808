import React, { useEffect, useState } from "react";
import { UserController } from "../../../../Redux/controllers/UserController";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../Redux/Slice/userSlice";
import Utility from "../../../../Utility/Utility";

export default function useOngoing() {
  const { token, sidebarFilterSelectedTab, showProjectDetails } = useSelector(
    (state) => state?.user
  );
  const selectedOngoingValueFromSidebar =
    sidebarFilterSelectedTab?.ongoingFilterTab;
  const dispatch = useDispatch();

  const [projects, setProjects] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);

  const fetchMyProjectByStatus = async () => {
    try {
      setIsProductsLoading(true);
      const response = await UserController.getProjectByStatus({
        status: selectedOngoingValueFromSidebar.toUpperCase(),
        token,
      });
      setProjects(response?.data?.data?.projects || []);
      setIsProductsLoading(false);
    } catch (error) {
      if (
        error.response?.data?.message === "Invalid token. Please log in again!"
      ) {
        dispatch(logout());
      } else {
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  useEffect(() => {
    fetchMyProjectByStatus();
  }, [selectedOngoingValueFromSidebar]);

  return {
    selectedOngoingValueFromSidebar,
    projects,
    isProductsLoading,
    showProjectDetails,
    noProjectFond: "No ongoing projects found.",
  };
}
