import React from "react";
import "./Similarproduct.css";
import { Col, Row } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa";

function Similarproduct({ similarProducts }) {
  return (
    <>
      <div id="similarproduct1">
        Similar products ({similarProducts?.length})
      </div>
      <Row>
        {similarProducts.map((item, index) => (
          <Col key={index} md={4} lg={4} xl={3} xxl={2} sm={6} xs={6}>
            <div id="standard1">
              <div id="standard2">
                {item?.name}
                {/* <span
                      id="heartIcon"
                      // onClick={() => setSelected(item?._id)}
                    >
                      <FaRegHeart />
                    </span> */}
              </div>
              {/* <div id="standard3">{item?.price}</div> */}
              <div id="standard5">
                <img
                  src={item?.productImages?.[0]}
                  alt={item?.name}
                  id="standard4"
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default React.memo(Similarproduct);
