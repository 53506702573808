import React from "react";
import "./ProjectCard.css";
import { Col, ProgressBar } from "react-bootstrap";
import { LuChevronRight } from "react-icons/lu";
import { FaChevronDown } from "react-icons/fa6";
import { RedirectToNewTab } from "../RedirectToNewTab/RedirectToNewTab";
import moment from "moment";

export default function ProjectCard({
  projectDetails,
  md,
  xs,
  sm,
  style,
  lg,
  onClick,
  key,
  showDetails,
  setShowProjectDetails,
}) {
  console.log("project d-----------------------------", projectDetails);

  const ShowProjectName = () => {
    return (
      <div id="card-bottom-container">
        <div id="card-title">{projectDetails?.roomStyle?.name || "N/A"}</div>
        <div>
          <LuChevronRight size={17} />
        </div>
      </div>
    );
  };
  const ShowProjectDetails = () => {
    return (
      <>
        <div id="card-open-title-container">
          <div id="card-open-title">
            Project ID : #
            {projectDetails?._id?.split("")?.reverse()?.slice(0, 7)}
          </div>
          <div>
            <FaChevronDown size={17} />
          </div>
        </div>
        <div id="card-open-body-container">
          {/* <div id="card-number-container">
            <div>
              <div id="card-number-text">{projectDetails?.resources}</div>
              <div>Resources</div>
            </div>
            <div>
              <div id="card-number-text">{projectDetails?.supervisors}</div>
              <div>Supervisors</div>
            </div>
            <div>
              <div id="card-number-text">{projectDetails?.engineers}</div>
              <div>Engineers</div>
            </div>
          </div> */}

          <ProgressBar
            now={parseInt(projectDetails?.progressPercentage) || 0}
            className="custom-progress-bar mt-1"
          />
          <div id="project-progress-text">
            {parseInt(projectDetails?.progressPercentage) || 0}% Project
            progress
          </div>

          <div id="onsite-container">
            <div id="onsite-status-label">Status</div>
            <div id="onsite-status-div">
              {projectDetails?.latestProjectStatus}
            </div>
          </div>

          {/* {projectDetails?.projectStatus === "PENDING" ? null : (
            <>
              {" "}
              <div id="onsite-container">
                <div id="onsite-status-label">On-Site Staff</div>
                <div id="onsite-status-div">Active</div>
              </div>
              <div id="project-manager-container">
                <div>
                  <div id="project-manger-name">Jon smith (Supervisor)</div>
                  <div id="project-manger-number">+91 434 545 3343</div>
                </div>
                <div id="active-div" />
              </div>
              <div id="project-manager-container">
                <div>
                  <div id="project-manger-name">Jon smith</div>
                  <div id="project-manger-number">+91 434 545 3343</div>
                </div>
                <div id="active-div" />
              </div>
            </>
          )} */}

          {/* <div className="LinkCardTime">
            <p className="LinkCardTimeDisc">Discussion phase</p>
            Slots Choosen:
            {moment(projectDetails?.consultationDetails?.date).format(
              "MMMM Do YYYY"
            )}
            ,
          </div>
          <div>
            Time: {projectDetails?.consultationDetails?.startTime}-
            {projectDetails?.consultationDetails?.endTime}
          </div>
          <div
            onClick={() => RedirectToNewTab(projectDetails?.link)}
            className="GoogleMeetLink"
          >
            {projectDetails?.link}
          </div> */}
        </div>
      </>
    );
  };

  return (
    <Col
      md={md || "4"}
      xs={xs || "12"}
      sm={sm || "12"}
      lg={lg || "4"}
      key={key}
    >
      <div id="card-container">
        <div>
          <img src={projectDetails?.roomStyle?.images?.[0]} id="card-image" />
          <div
            className="semitransparent-button"
            onClick={() => setShowProjectDetails(projectDetails)}
          >
            View Details
          </div>
        </div>
        <div onClick={onClick}>
          {!showDetails ? <ShowProjectName /> : <ShowProjectDetails />}
        </div>
      </div>
    </Col>
  );
}
