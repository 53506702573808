import React from "react";
import "./MyAccountSidebar.css";
import { MyAccountData } from "./MyAccountData";
import { useDispatch, useSelector } from "react-redux";
import {
  setMyAccountSelectedTab,
  setNavbarSelectedTab,
} from "../../../Redux/Slice/userSlice";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

export default function MyAccountSidebar({ handleClose }) {
  const dispatch = useDispatch();
  const { myAccountSelectedTab } = useSelector((state) => state.user);
  console.log("my account sele", myAccountSelectedTab);
  return (
    <div className="mt-5">
      {MyAccountData?.map((item, i) => {
        const selected = myAccountSelectedTab === item?.value;
        return (
          <div
            key={i}
            id="myAccountSidebar1"
            className={selected ? "active" : ""}
            onClick={() => {
              dispatch(
                setNavbarSelectedTab({
                  exploreORmyproject: "",
                  tabSelectedFrom2ndNav: "myaccount",
                })
              );
              dispatch(setMyAccountSelectedTab(item?.value));
              if (handleClose) {
                handleClose();
              }
            }}
          >
            <div>
              {" "}
              <item.icon size={22} color={selected ? "#1551ED" : "#747F9C"} />
              <span id="myAccountSidebar3" className={selected ? "active" : ""}>
                {item?.name}
              </span>
            </div>
            {selected ? (
              <FaChevronLeft color="#1551ED" style={{ marginLeft: 10 }} />
            ) : (
              <FaChevronRight style={{ marginLeft: 10 }} />
            )}
          </div>
        );
      })}
    </div>
  );
}
