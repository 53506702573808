import React from "react";
import "./Material.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import Materialtabs from "./Materialtabs/Materialtabs";
import useMaterial from "../hooks/useMaterial";
import Loader from "../../../components/Loader/Loader";

export default function Material() {
  const {
    products,
    searchValue,
    isProductsLoading,
    classCategory,
    isClassLoading,
    setActiveClass,
    handleSearch,
  } = useMaterial();

  console.log("111111111111111", classCategory, isClassLoading);

  return (
    <>
      <Container fluid>
        <Row id="material2">
          <Col md={12} lg={12} sm={12} xs={12}>
            <div id="material1">
              <Row>
                <Col md={7} lg={7} sm={12} xs={12}>
                  <div id="material3">What you are looking for ?</div>
                </Col>
                <Col md={5} lg={5} sm={12} xs={12}>
                  <div className="search-contain">
                    <Form.Group className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        size="lg"
                        id="material4"
                        value={searchValue}
                        onChange={handleSearch}
                      />
                      <CiSearch className="search-ico" />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {!isClassLoading ? (
          <Materialtabs
            materialsDataArray={products}
            isProductsLoading={isProductsLoading}
            classCategoryArray={classCategory}
            isClassLoading={isClassLoading}
            setActiveClass={setActiveClass}
          />
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
}
