import React, { useState } from "react";
import "./Ongoing.css";
import { Row, Col, Alert } from "react-bootstrap";
import ProjectCard from "../../../components/ProjectCard/ProjectCard";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import { useDispatch, useSelector } from "react-redux";
import { setShowProjectDetails } from "../../../Redux/Slice/userSlice";
import useOngoing from "./hooks/useOngoing";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Ongoing() {
  const [selectedProject, setSelectedProject] = useState(null);
  const dispatch = useDispatch();

  const { isProductsLoading, projects, showProjectDetails, noProjectFond } =
    useOngoing();

  const handleCardClick = (index) => {
    setSelectedProject(selectedProject === index ? null : index);
  };

  const ShowProjectDetails = (projectDetails) => {
    dispatch(setShowProjectDetails(projectDetails));
  };

  // console.log("projects111111111111111111111111111111111111", projects);

  return (
    <Row style={{ paddingLeft: "0.6rem", paddingRight: "0.6rem" }}>
      {showProjectDetails ? (
        <ProjectDetails projectId={showProjectDetails?._id} />
      ) : isProductsLoading ? (
        Array.from({ length: 6 }).map((_, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={4} id="noprojectfound1">
            <Skeleton height={230} width={"100%"} borderRadius={"1.3rem"} />
          </Col>
        ))
      ) : projects?.length > 0 ? (
        projects.map((item, index) => (
          <ProjectCard
            key={index}
            projectDetails={item}
            onClick={() => handleCardClick(index)}
            showDetails={selectedProject === index}
            setShowProjectDetails={ShowProjectDetails}
          />
        ))
      ) : (
        <Col>
          <div id="no-projects-found">{noProjectFond}</div>
        </Col>
      )}
    </Row>
  );
}
