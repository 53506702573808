import { Offcanvas } from "react-bootstrap";
import FilterButtonComp from "../../FilterButtonComp/FilterButtonComp";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarFilterSelectedTab } from "../../../Redux/Slice/userSlice";

const buttonData = [
  { value: "ALL", text: "All Projects" },
  {
    value: "PENDING",
    text: "Enquiry",
  },
  {
    value: "DESIGN",
    text: "DESIGN",
  },
  {
    value: "PRECONSTRUCTION",
    text: "Pre-Construction",
  },
  // {
  //   value: "construction",
  //   text: "Construction",
  // },
  // {
  //   value: "interior",
  //   text: "Interior",
  // },
];

// filter off canvas for mobile view
export default function OngoingProjectsFilterMobile({
  show,
  handleClose,
  ...props
}) {
  const dispatch = useDispatch();
  const selectedValue = useSelector(
    (state) => state.user?.sidebarFilterSelectedTab?.ongoingFilterTab
  );

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      {...props}
      style={{ height: 400 }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Category</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {buttonData?.map((item, i) => (
          <FilterButtonComp
            key={i}
            title={item?.text}
            selected={item?.value === selectedValue && "true"}
            onClick={() => {
              dispatch(
                setSidebarFilterSelectedTab({
                  ongoingFilterTab: item?.value,
                  materialFilterTab: "interiorDesignMaterials",
                  searchMaterialFilterValue: "",
                })
              );
              handleClose();
            }}
          />
        ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
