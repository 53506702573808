import axios from "axios";

// const API_BASE_URL = "http://192.168.29.167:8002/api/";
// const API_BASE_URL = "http://192.168.1.17:7090/api/";

// adil local ip address
// const API_BASE_URL = "http://192.168.29.167:4000/api/";

// abhishek

// const API_BASE_URL = "http://192.168.29.30:4000/api/";
// const API_BASE_URL = "http://192.168.1.28:4000/api/";

// const API_BASE_URL = "http://localhost:4000/api/";

// const API_BASE_URL = "http://localhost:4000/api/";

// my local ip address
const API_BASE_URL = "https://api.bricxy.com/api/";

const CDN_URL = "https://cdn.bricxy.com";

// onrender url
// const API_BASE_URL = "https://backend-pe1b.onrender.com/api/";

const apiCaller = (
  uri,
  method = "POST",
  data = {},
  token = null,
  contentType = null,
  onUploadProgress
) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API_BASE_URL + uri,
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onUploadProgress(percentCompleted);
        }
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(`Api Caller Response Of ${uri} : `, response?.data);
        resolve(response);
      })
      .catch(function (error) {
        console.log(`Api Caller Error Of ${uri} : `, error);
        reject(error);
      });
  });
};

const uploadApiCaller = (uri, data, onProgress) => {
  return axios.post(API_BASE_URL + uri, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    },
  });
};

const deleteApicaller = (uri, token) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: API_BASE_URL + uri,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const HttpClient = {
  apiCaller,
  API_BASE_URL,
  CDN_URL,
  deleteApicaller,
  uploadApiCaller,
};
