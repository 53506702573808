import React from "react";
import "./Sidebar.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import BricxyLogo from "../../assets/UserHeader/BrixcylogoNew.png";
import Home from "../../assets/sidebar/houseImage.svg";
import { GoArrowRight } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import MyAccountSidebar from "./MyAccountSidebar/MyAccountSidebar";
import MaterialFilterComp from "./MaterialFilterComp/MaterialFilterComp";
import OngoingProjectsFilterComp from "./OngoingProjectsFilterComp/OngoingProjectsFilterComp";
import { useQuery } from "@tanstack/react-query";
import Utility from "../../Utility/Utility";
import { setSidebarFilterSelectedTab } from "../../Redux/Slice/userSlice";
import { UserController } from "../../Redux/controllers/UserController";

export default function Sidebar() {
  const { navbarSelectedTab, showProjectDetails, token } = useSelector(
    (state) => state.user
  );

  const exploreOrMyproject = navbarSelectedTab?.exploreORmyproject;
  const TabSelectedFrom2ndNav = navbarSelectedTab?.tabSelectedFrom2ndNav;
  const paths = ["discussions", "construction", "support", "wishlist"];
  const checkShowFilters = paths?.includes(TabSelectedFrom2ndNav);
  const dispatch = useDispatch();

  const fetchCategoriesOfMaterials = async (token) => {
    try {
      const response = await UserController.getCategories({
        type: "INTERIOR",
        token,
      });

      const categories = response.data.data || [];

      if (categories.length > 0) {
        dispatch(
          setSidebarFilterSelectedTab({
            ongoingFilterTab: "ALL",
            materialFilterTab: categories[0],
            searchMaterialFilterValue: "",
          })
        );
      }
      return categories;
    } catch (error) {
      Utility.eToast(
        error.response?.data?.message ||
          error.message ||
          "Oops! Something went wrong"
      );
    }
  };

  const { data: buttonData } = useQuery({
    queryKey: ["categoriesInterior"], // Query key
    queryFn: () => fetchCategoriesOfMaterials(token), // Fetch function
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    onError: (error) => {
      console.error("error", error);
    },
  });

  return (
    <Row>
      <Col id="sidebar1">
        {/* logo with filter component */}
        <div id="sidebar2">
          <img src={BricxyLogo} alt="Bricxy" id="sidebar2Logo" />
          {/* any filter component add here */}
          {checkShowFilters ||
          (showProjectDetails &&
            exploreOrMyproject ===
              "myproject") ? null : TabSelectedFrom2ndNav === "myaccount" ? (
            <MyAccountSidebar />
          ) : TabSelectedFrom2ndNav === "ongoing" ? (
            <OngoingProjectsFilterComp />
          ) : TabSelectedFrom2ndNav === "material" ? (
            <MaterialFilterComp buttonData={buttonData} />
          ) : null}
        </div>

        {/* last house with offer div */}
        <div id="sidebar3">
          <div id="sidebar4">
            <img src={Home} alt="Image" id="sidebar4Image" />
            <div id="sidebar5">
              Build your
              <br />
              Dream Home
            </div>
          </div>
          <div id="sidebar4" className="mt-1">
            <div id="sidebar6">
              13% Discount on
              <br />
              Total Project
            </div>
            <Button id="sidebar7">
              Get Offer Now <GoArrowRight />
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}
