import React from "react";
import "./OngoingProjectsFilterComp.css";
import FilterButtonComp from "../../FilterButtonComp/FilterButtonComp";
// import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import FilterImage from "../../../assets/sidebar/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarFilterSelectedTab } from "../../../Redux/Slice/userSlice";

const buttonData = [
  { value: "ALL", text: "All Projects" },
  {
    value: "PENDING",
    text: "Enquiry",
  },
  {
    value: "DESIGN",
    text: "DESIGN",
  },
  {
    value: "WORK_IN_DRAWING",
    text: "Pre-Construction",
  },
  // {
  //   value: "construction",
  //   text: "Construction",
  // },
  // {
  //   value: "interior",
  //   text: "Interior",
  // },
];

export default function OngoingProjectsFilterComp() {
  const dispatch = useDispatch();
  const selectedValue = useSelector(
    (state) => state.user?.sidebarFilterSelectedTab?.ongoingFilterTab
  );

  return (
    <div className="mt-3">
      <div
        id="ongoingFilterComp1"
        style={{ borderBottom: "1px solid #DBDBDB" }}
      >
        <span className="me-5" id="ongoingFilterComp2">
          FILTER
        </span>
        <img src={FilterImage} alt="Filter" />
      </div>
      <div
        id="ongoingFilterComp1"
        style={{ borderBottom: "1px solid #EFEFEF" }}
      >
        <span className="me-5" id="ongoingFilterComp3">
          Category
        </span>
        {/* {openCategory ? (
          <HiOutlineChevronDown onClick={handleOpenCategory} />
        ) : (
          <HiOutlineChevronUp onClick={handleOpenCategory} />
        )} */}
      </div>

      {buttonData?.map((item, i) => (
        <FilterButtonComp
          key={i}
          title={item?.text}
          selected={item?.value === selectedValue && "true"}
          onClick={() =>
            dispatch(
              setSidebarFilterSelectedTab({
                ongoingFilterTab: item?.value,
                materialFilterTab: "interiorDesignMaterials",
              })
            )
          }
        />
      ))}
    </div>
  );
}
