import { useState, useEffect } from "react";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../Utility/Utility";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux/Slice/userSlice";

function useMaterial() {
  const selectedMaterialValueFromSidebar = useSelector(
    (state) => state.user?.sidebarFilterSelectedTab?.materialFilterTab
  );
  const { token } = useSelector((state) => state.user);
  const categoryId = selectedMaterialValueFromSidebar?._id;
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [activeClass, setActiveClass] = useState(null);

  const fetchProductByCategory = async (
    categoryId,
    token,
    selectedClassId,
    intialClassId
  ) => {
    try {
      const response = await UserController.getProductsByCategoryId({
        id: categoryId,
        token,
        classId: selectedClassId || intialClassId,
      });
      return response?.data.data.products || [];
    } catch (error) {
      console.log("error in product categor id", error);
      if (
        error.response?.data?.message === "Invalid token. Please log in again!"
      ) {
        dispatch(logout());
      } else {
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  const fetchBaseClass = async (token) => {
    try {
      const response = await UserController.getBaseClassCategory({ token });
      return response?.data?.data || [];
    } catch (error) {
      console.log("error in product categor id", error);
      if (
        error.response?.data?.message === "Invalid token. Please log in again!"
      ) {
        dispatch(logout());
      } else {
        Utility.eToast(
          error.response?.data?.message ||
            error.message ||
            "Oops! Something went wrong"
        );
      }
    }
  };

  // Fetch the class categories
  const { data: classCategory = [], isLoading: isClassLoading } = useQuery({
    queryKey: ["classCategory"],
    queryFn: () => fetchBaseClass(token),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
    onError: (error) => {
      console.error("Error fetching class categories", error);
    },
    onSuccess: (data) => {
      if (data.length > 0 && !activeClass) {
        setActiveClass(data[0]); // Set initial active class
      }
    },
  });

  // Fetch the products based on selected class and category
  const { data: products = [], isLoading: isProductsLoading } = useQuery({
    queryKey: ["productsByCategory", categoryId, activeClass?._id],
    queryFn: () =>
      fetchProductByCategory(
        categoryId,
        token,
        activeClass?._id,
        classCategory[0]?._id
      ),
    enabled: !!categoryId && !!activeClass && !!token,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    onError: (error) => {
      console.error("Error fetching products", error);
    },
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredProducts = products?.filter((product) =>
    product?.name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return {
    products: filteredProducts,
    isProductsLoading,
    searchValue,
    classCategory,
    isClassLoading,
    setActiveClass,
    handleSearch,
  };
}

export default useMaterial;
