import React, { useEffect } from "react";
import "./MaterialFilterComp.css";
import FilterButtonComp from "../../FilterButtonComp/FilterButtonComp";
import { HiOutlineChevronDown } from "react-icons/hi";
import FilterImage from "../../../assets/sidebar/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarFilterSelectedTab } from "../../../Redux/Slice/userSlice";
import { useQuery } from "@tanstack/react-query";
import Utility from "../../../Utility/Utility";
import { UserController } from "../../../Redux/controllers/UserController";

export default function MaterialFilterComp({ buttonData }) {
  const dispatch = useDispatch();
  const selectedValue = useSelector(
    (state) => state.user?.sidebarFilterSelectedTab?.materialFilterTab
  );

  return (
    <>
      <div
        id="materialFilterComp1"
        style={{ borderBottom: "1px solid #DBDBDB" }}
      >
        <span className="me-5" id="materialFilterComp2">
          FILTER
        </span>
        <img src={FilterImage} alt="Filter" />
      </div>
      <div
        id="materialFilterComp1"
        style={{ borderBottom: "1px solid #EFEFEF" }}
      >
        <span className="me-5" id="materialFilterComp3">
          Category
        </span>
        {/* <HiOutlineChevronDown /> */}
      </div>
      {buttonData?.map((item, i) => (
        <FilterButtonComp
          key={i}
          title={item?.categoryName}
          selected={
            item?.categoryName === selectedValue?.categoryName && "true"
          }
          onClick={() =>
            dispatch(
              setSidebarFilterSelectedTab({
                ongoingFilterTab: "ALL",
                materialFilterTab: item,
                searchMaterialFilterValue: "",
              })
            )
          }
        />
      ))}
    </>
  );
}
