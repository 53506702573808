import React, { useEffect, useState } from "react";
import "./Comments.css";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";

function Comments({ allreview }) {
  const getFirstLetter = (firstName) => {
    return firstName ? firstName.charAt(0).toUpperCase() : "N/A";
  };

  return (
    <Container fluid>
      <Row id="comments2">
        {allreview.map((comment) => (
          <Col key={comment?._id} md={6} lg={6} sm={12} xs={12}>
            <div id="comments1">
              <Row>
                <Col md={2} lg={2} sm={3} xs={3}>
                  <div className="initials-circle">
                    {getFirstLetter(comment?.user?.firstName)}
                  </div>
                </Col>
                <Col md={10} lg={10} sm={9} xs={9}>
                  <div id="comments3">
                    {`${comment?.user?.firstName || "N/A"} ${
                      comment?.user?.lastName || "N/A"
                    }`}
                  </div>
                  <div id="comments4">{comment?.user?.email || "N/A"}</div>
                  <div>
                    <Rating
                      initialValue={comment?.rating}
                      size={25}
                      fillColor="#1757FF"
                      emptyColor="#C0CDE4"
                      readonly
                    />
                  </div>
                </Col>
                <div id="comments5">{comment?.review || "N/A"}</div>
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default React.memo(Comments);
